export const whosponsor = [
  {
    id: 1,
    title: "Thought Leadership",
    description: [
      "As a sponsor at Finnovex, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.",
    ],
    location: "left",
    image: "1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY",
  },
  {
    id: 2,
    title: "PREMIUM BRANDING",
    description: [
      "We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ",
      "As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, Media Partnersships and social media like how we have built within a year brand like Finnovex Global Series in Middle East and Africa.Find out more about team working on Finnovex who successfully executed Finnovex Global Series www.finnovex.com",
    ],
    location: "right",
    image: "1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9",
  },
  {
    id: 3,
    title: "FEATURED NETWORKING EVENTS / FACE TIME:",
    description: [
      "Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.",
      "Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events,we have successfully Introduced more than 100+ Banks,FI meetings with our sponsors find out more on our YouTube Channel.",
    ],
    location: "left",
    image: "1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ",
  },
];

export const sponsors = [
  { image: "microsoft.png" },
  { image: "hcl.jpg" },
  { image: "knoxwire.jpg" },
  { image: "backbase.png" },
  { image: "newgen.png" },
  { image: "infosys.jpg" },
  { image: "azentio.png" },
  { image: "veripark.png" },
  { image: "genesys.png" },
  { image: "oz.png" },
  { image: "yellow.png" },
  { image: "vismaya.png" },
  { image: "moengage.png" },
  { image: "pursoft.png" },
  { image: "ecsFin.png" },
  { image: "cognerium.png" },
  { image: "neosoft.png" },
  { image: "technotree.png" },
  { image: "antariksh.png" },
  { image: "elev8.png" },
  { image: "qifc.png" },
  { image: "kigali.png" },
  { image: "accurascan.png" },
  { image: "hilton.png" },
  { image: "worldpay.png" },
  { image: "authlete.png" },
  { image: "tata.jpeg" },
  { image: "bankservAfrica.png" },
  { image: "daon.png" },
  { image: "comforte.png" },
  { image: "concerto.png" },
  { image: "da.png" },
  { image: "kissflow.jpg" },
  { image: "onespan.png" },
  { image: "smartstream.png" },
  { image: "temenos.jpg" },
  { image: "tracefinancial.png" },
  { image: "visa.png" },
  { image: "zentity.png" },
];

export const govsponsors = [];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const live = [
  {
    id: 1,
    link: "Marketing-Strategies-For-Fintech-In-2020",
    description: "Marketing Strategies For Fintech In 2020",
    content:
      "Getting busier and more competitive by the day, it's important for those involved to consider strategies for how to stand out and sustain business.",
    image: "1.png",
  },
];

export const speakers = [
  {
    name: "Baseer Mohammed",
    title: "Chief Executive Officer",
    company: "I & M Bank (T) Limited",
    image: "baseer.jpg",
    companyImage: "imb.png",
  },

  {
    name: "Amrit Gayan",
    title: "Chief Operating Officer",
    company: "SBM Bank Kenya Limited",
    image: "amrit.png",
    companyImage: "sbm1.png",
  },
  {
    name: "Walubita Luwabelwa ",
    title: "Chief Compliance and Data Privacy Officer",
    company: "Stanbic Bank, Zambia",
    image: "walubita.jpg",
    companyImage: "ncba.png",
  },
  {
    name: "Varghese Thambi",
    title: "Managing Director",
    company: "Diamond Trust Bank Uganda Ltd",
    image: "Varghese.jpg",
    companyImage: "dtb.png",
    link: "https://www.linkedin.com/in/varghese-thambi-16191243/",
  },
  {
    name: "Steve Njenga",
    title: "Group CIO Payments",
    company: "Equity Bank Kenya Limited",
    image: "Steve.jpg",
    companyImage: "Equity.png",
  },
  {
    name: "Sulaiman Mugalasi",
    title: "Head of Information Technology",
    company: "KCB Bank Uganda",
    image: "sulaiman.jpg",
    companyImage: "kcb.png",
  },

  {
    name: "Stanley Chege",
    title: "Group  Chief Information Officer",
    company: "Jubilee Holdings",
    image: "stanley.jpg",
    companyImage: "MG.png",
    link: "https://www.linkedin.com/in/stanley-mwangi-chege-569b3717/",
  },
  {
    name: "Faheem Ali",
    title: "Chief Risk Officer (CRO) ",
    company: "Musoni Kenya Ltd.",
    image: "Faheem.jpg",
    companyImage: "musoni.png",
    link: "https://www.linkedin.com/in/faheem-ali-khowaja/",
  },
  {
    name: "Dr. Lanre Bamisebi",
    title: "Group Director IT & Ops",
    company: "Equity Bank (Kenya) Limited",
    image: "Bamisebi.jpg",
    companyImage: "Equity.png",
  },
  {
    name: "Olanma John-Agbaje",
    title: "COO",
    company: "Access Bank (Rwanda)",
    image: "olanma.jpg",
    companyImage: "access.png",
  },
  {
    name: "Patricia Mugabe ",
    title: "Head of Strategy, Innovation and Digital Transformation",
    company: "Bank ABC, Zimbabwe",
    image: "Patricia.jpg",
    companyImage: "bancABC.jpg",
  },

  {
    name: "Yared Endale Woldesenbet",
    title: "Senior Director, Digital Financial Services",
    company: "Cooperative Bank of Oromia",
    image: "yared.jpg",
    companyImage: "coop.jpg",
  },
  {
    name: "Raoul Ndayambaje",
    title: "Head of Digital Banking and E-channels",
    company: "Cogebanque, Rwanda",
    image: "Raoul.jpg",
    companyImage: "Cogebanque.jpg",
  },
  {
    name: "Abdi Mohamed",
    title: "Managing Director/ CEO",
    company: "Absa Bank Tanzania",
    image: "Abdi Mohamed.jpg",
    companyImage: "absa.png",
  },
  {
    name: "Dr. Henry Clarke Kisembo, PhD",
    title: "Chairman - National Mobile Financial Services",
    company: "Central Bank Of Uganda",
    image: "henry.jpg",
    companyImage: undefined,
  },
  {
    name: "Mutisunge​ Zulu",
    title: "Chief Risk Officer",
    company: "Zambia National Commercial Bank",
    image: "mutisunge.jpg",
    companyImage: "zanaco.jpg",
    link: "https://www.linkedin.com/in/stanley-mwangi-chege-569b3717/",
  },
  {
    name: "Victor Adewusi",
    title: "Director, Data Strategy and Analytics",
    company: "Equity Bank (Kenya) Limited",
    image: "victor.png",
    companyImage: "Equity.png",
  },

  {
    name: "Karanja Gichiri	",
    title: "Vice President-Banking, Capital Markets and Advisory",
    company: "Citi	",
    companyImage: "citi.jpeg",
    image: "Karanja Gichiri.jpg",
  },
  {
    name: "Francis Mumbi",
    title: "Head, Data Office",
    company: "Stanbic Bank",
    companyImage: "ncba.png",
    image: "Francis Mumbi.jpg",
  },

  {
    name: "Bonaventure Ngala	",
    title: "Digital Forensic Expert	",
    company: "Kenya Revenue Authority	",
    companyImage: "KRA.png",
    image: "Bonaventure.jpg",
  },

  {
    name: "Hartnell Ndungi	",
    title: "Chief Data Officer	",
    company: "Absa Bank Kenya 	",
    companyImage: "absa.png",
    image: "hartnell.jpg",
  },

  {
    name: "Sahil Arya	",
    title: "Chief Information Officer	",
    company: "Gulf African Bank	",
    companyImage: "gfb.jpeg",
    image: "Sahil Arya.jpeg",
  },

  {
    name: "Supriyo Biswas	",
    title: "Chief Information Security Officer	",
    company: "SBM Bank Kenya	",
    companyImage: "sbm1.png",
    image: "supriyo.jpg",
  },

  {
    name: "Michael, Michie	",
    title: "CTO ",
    company: "Solv Kenya	Standard Chartered Bank	",
    companyImage: "st.png",
    image: "Michael michie.jpeg",
  },

  {
    name: "Daniel Murage	",
    title: "Chief Financial Officer	",
    company: "UBA Kenya Bank	",
    companyImage: "uba.jpeg",
    image: "Daniel Murage.jpeg",
  },

  {
    name: "Julius Orayo	",
    title: "Group Chief Information Officer	",
    company: "Jubilee Insurance	",
    companyImage: undefined,
    image: "Julius Orayo.jpg",
  },

  {
    name: "Chike Isiuwe	",
    title: "Chief Executive Officer and Managing Director	",
    company: "UBA Kenya Bank	",
    companyImage: "uba.jpeg",
    image: "chike.jpg",
  },
  {
    name: "Wole Odeleye	",
    title: "Technology Industry Lead, Financial Services	",
    company: "Microsoft	",
    companyImage: "microsoft.png",
    image: "Wole.jpg",
  },
  {
    name: "Roland Brandli",
    title: "Strategic Product Manager",
    company: "SmartStream	",
    companyImage: "smartstream.jpeg",
    image: "Roland Headshot.jpeg",
  },

  {
    name: "Stella Nyagah 	",
    title: "Senior Partner Specialist	",
    company: "Microsoft	",
    companyImage: "microsoft.png",
    image: "Stella.jpg",
  },

  {
    name: "L Guru Raghavendran	",
    title: "Senior Vice President - Banking & Capital Markets Practice",
    company: "Azentio Software",
    companyImage: "azentio.jpeg",
    image: "Lguru.jpg",
  },
  {
    name: "Shekar Murthy",
    title: "Chief Customer Officer",
    company: "Yellow.ai",
    companyImage: "yellow.jpeg",
    image: "Shekar Murthy.png",
  },
  {
    name: "Gunjan Dhingra",
    title: "Senior Director",
    company: "PureSoftware",
    companyImage: "pure.jpeg",
    image: "Speaker - Gunjan Dhingra.jpeg",
  },

  // {
  //   name: 'Simon Njuguna Mbogo',
  //   title: 'Group Information Technology Director',
  //   company: 'NCBA Group, Kenya',
  //   image: 'simon.jpg',
  //   companyImage: 'ncba.png',
  // },

  // ////

  // {
  //   name: 'Stephen Kagaruki',
  //   title: 'Head of Innovation & Digital Transformation',
  //   company: 'CRDB, Tanzania',
  //   image: 'Stephen Kagaruki.jpg',
  //   companyImage: 'crdb.png',
  // },

  // ////

  // // {
  // //   name: "Rohit Dhawan",
  // //   title: "Chief Information Officer",
  // //   company: "Bank of Kigali (Rwanda)",
  // //   image: "rohit.jpg",
  // //   companyImage: "bk.jpg",
  // //   link: "https://www.linkedin.com/in/rohitdhawan/  ",
  // // },

  // {
  //   name: 'Ibrahim Sagna',
  //   title: 'Director and Global Head, Advisory & Capital Markets',
  //   company: 'African Export-Import Bank',
  //   image: 'sagna.jpg',
  //   companyImage: 'imb.png',
  // },

  // ///
  // {
  //   name: 'Deogratius Mosha',
  //   title: 'Head, Digital Banking and Retail Banking',
  //   company: 'National Bank of Commerce, Tanzania',
  //   image: 'deogratius.jpg',
  //   companyImage: 'nbc.png',
  // },
  // /////

  // // {
  // //   name: "Kevin Bwaley",
  // //   title: "Centre Head Platinum Banking ",
  // //   company: "KCB Bank Group",
  // //   image: "kevin.jpg",
  // //   companyImage: "kcb.png",
  // // },

  // {
  //   name: 'Dr. Peter Njuguna, PhD',
  //   title: 'Chief Information Officer',
  //   company: 'Co-operative Bank of Kenya Ltd',
  //   image: 'Peter.jpg',
  //   companyImage: 'cbok.png',
  //   link: 'https://www.linkedin.com/in/dr-peter-kamau-njuguna-ph-d-64180a83/',
  // },

  // {
  //   name: 'Michael Gichure  ',
  //   title: 'Associate Director, Product Management,Transaction Banking',
  //   company: 'Standard Chartered Bank Kenya Ltd',
  //   image: 'Michael.jpg',
  //   companyImage: 'st.png',
  // },

  // ///
];
