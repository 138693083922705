import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: `url(${require("assets/images/asset7.jpg")})`,
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            {" "}
            <Col lg={12}>
              <div className="text-center">
                <h2 className={`text-900 text-white`}>Testimonials</h2>
                <p className={`text-400 text-white mb-4 `}></p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Slider {...settings}>
                {content.map((c) => (
                  <div className="container ">
                    <div className="row">
                      <div className="col-lg-4">
                        <img
                          alt="..."
                          className="rounded-circle"
                          width="100%"
                          src={require(`assets/images/speakers/${c.speaker}`)}
                        />
                      </div>
                      <div className="col-lg-8">
                        <h4
                          className="text-white text-400 px-3"
                          dangerouslySetInnerHTML={{ __html: c.testimonial }}
                        />
                        <br />
                        <h4
                          className="text-white text-400"
                          style={{ fontWeight: 700 }}
                          dangerouslySetInnerHTML={{ __html: c.by }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial:
      "It was an absolute pleasure to participate and I am glad Finnovex, as well as the participants found my contribution useful. Well done for organising a successful event",
    by: "Kariuki Ngari<br/><small>Chief Executive Officer</small><br/>Standard Chartered Bank",
    speaker: "1677512338458-Kariuki_Ngari.jpg",
  },
  {
    testimonial:
      "The EXIBEX team were courteous, offered excellent service throughout the entire conference and gave us a warm welcome. Their media team also did a fantastic job in capturing the moments throughout the conference and of delegates.",
    by: "Kevin Mwandau<br/><small>Assistant Manager CIB</small><br/>Absa Bank Kenya.",
    speaker: "Kevin Mwandau.jpeg",
  },
  {
    testimonial:
      "Great team, your people skills and logistic execution is indeed the best. Looking forward to again partner with you. Until next time, take care and all the best.",

    by: "Ian Collins Mungai<br/><small>Global Markets Operations.</small><br/>Standard Investment Bank",
    speaker: "Ian Collins.jpeg",
  },

  {
    testimonial: `
    I have attended several conferences organized by the Finnovex team and, given the unexpected Covid-19 hiatus, this conference was by far the most memorable! I thoroughly enjoyed it and came away with new knowledge and insights. It is challenging to keep up with the fast changing technologies and rapidly evolving customer expectations – these conferences are an excellent avenue to help keep up. All speakers were engaging, entertaining, knowledgeable and very informative – they know their stuff! I look forward to the next conference.

	`,
    by: "Steve Njenga<br/><small>Group CIO Payments</small><br/>Equity bank Kenya ",
    speaker: "Steve.jpg",
  },
  {
    testimonial: `
    Thankyou, I particularly liked that all sessions, presentations and panels were in one room so we could make the most out of it. The participation from CXO’s of leading institutions along with their teams made the event very open and interactive, with great networking opportunities. The panel discussions were relevant and alive. Thankyou for inviting me to participate in the panels. I enjoyed this experience and hearing the views from my peers and veterans of the industry. Look forward to the next event in the series.
	`,
    by: `Gunjan Dhingra<br/>
    <small>Senior Director Africa</small><br/>PureSoftware
    `,
    speaker: "Speaker - Gunjan Dhingra.jpeg",
  },
  {
    testimonial: `
    Thanks for having me and the UBA Team. It was a great learning opportunity for us and we look forward to support and attend your programmes in the future.
	`,
    by: `Chike Isiuwe<br/>
    <small>Managing Director/CEO</small><br/>UBA Kenya Bank Ltd
    `,
    speaker: "chike.jpg",
  },
];

export default Testimonials;
