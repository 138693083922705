import React from "react";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import TextGrid from "components/TextGrid";
import Event from "components/Event";
import Exibex from "components/Exibex";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Speaker from "components/Speaker";
import Spotlight from "components/Spotlight";
import SponsorOld from "components/SponsorOld";

import Awards from "components/Awards";
import Stats from "components/Stats";
import Register from "components/Register";
import Testimonials from "components/Testimonials";

// import Register from 'components/Register';

import { speakers, sponsorsPage } from "./content";

import ScrollUpButton from "react-scroll-up-button";
import { size } from "components/common";
import Sponsor from "components/SponsorNew";
import Quotes from "components/Quotes";
import MainStats from "components/MainStats";

function Home() {
  return (
    <div id="outer-container">
      <div
        style={{
          background: "#000",
          backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Navbar page="home" home />
        <ScrollUpButton />
        <LandingPageHeader home />
        <Quotes />
        <MainStats />
        <TextGrid />
        <Spotlight />

        <section id="speakers" />
        <Speaker speakers={speakers} />
      </div>
      <Event />
      <div
        style={{
          background: "#000",
          backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "100%",
        }}
      >
        <Awards />
        <section id="partners" />
        <Sponsor />
        <SponsorOld sponsors={sponsorsPage} />
        {/* <Price /> */}
        <section id="register" />
        <Register title={"Register"} />
        <Testimonials />
        <Exibex />

        <Footer />
      </div>
    </div>
  );
}

export default Home;
