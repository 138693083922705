import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import SpeakerAll from "components/SpeakerAll";

import { speakers } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function Speakers() {
  return (
    <div id="outer-container">
      <div
        style={{
          background: "#000",
          backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Navbar />
        <ScrollUpButton />
        <ProfilePageHeader title="2024 SPEAKERS" />
        <SpeakerAll speakers={speakers} />
        <Footer />
      </div>
    </div>
  );
}

export default Speakers;
