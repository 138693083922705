import React from "react";

import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";

function MainStats() {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <div
      style={{
        background: "transparent",
      }}
      className="section py-5"
      data-parallax={true}
    >
      <Container className="">
        <Row className={"justify-content-center row-cols-2"}>
          <Col lg={3} xs={6} className="text-left align-self-center">
            {size ? (
              <h2 className="text-700 text-white m-0">ATTENDEE PROFILE</h2>
            ) : (
              <h4 className="text-700 text-white m-0">ATTENDEE PROFILE</h4>
            )}
          </Col>
          {content.map((c, index) => (
            <Col className="text-center text-white px-1" lg={3}>
              <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5 + index / 2}>
                <div
                  className="align-self-center pt-2 pb-4 px-1 mb-2 stat-div"
                  style={{ aspectRatio: "1/1" }}
                >
                  <h1
                    className="text-700 mb-4"
                    id="count"
                    style={{ fontSize: size ? "70px" : "40px" }}
                  >
                    <CountUp end={c.value} />
                    <small style={{ color: "#ffffff" }}>%</small>
                  </h1>
                  <h5 className="text-400 my-0" style={{ fontSize: size ? "20px" : "12px" }}>
                    {c.title}
                  </h5>
                </div>
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="pt-2">
        <Row className={"justify-content-center row-cols-2"}>
          <Col lg={3} className="text-left align-self-center">
            {size ? (
              <h3 className="text-700 text-white m-0">
                {" "}
                HOW DID THE SPONSORS & EXHIBITORS
                <br />
                BENEFIT IN THE 2023 GLOBAL SERIES ?
              </h3>
            ) : (
              <h5 className="text-700 text-white m-0">
                {" "}
                HOW DID THE SPONSORS & EXHIBITORS
                <br />
                BENEFIT IN THE 2023 GLOBAL SERIES ?
              </h5>
            )}
          </Col>
          {content1.map((c, index) => (
            <Col className="text-center text-white px-1" lg={3}>
              <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5 + index / 2}>
                <div
                  className="align-self-center pt-2 pb-4 px-1 mb-2 stat-div"
                  style={{ aspectRatio: "1/1" }}
                >
                  <h1
                    className="text-700 my-4"
                    id="count"
                    style={{ fontSize: size ? "70px" : "40px" }}
                  >
                    <CountUp end={c.value} />
                    <small style={{ color: "#ffffff" }}>%</small>
                  </h1>
                  <h5 className="text-400 my-0" style={{ fontSize: size ? "20px" : "12px" }}>
                    {c.title}
                  </h5>
                </div>
              </ScrollAnimation>
            </Col>
          ))}
          {content2.map((c, index) => (
            <Col className="text-center text-white px-1" lg={3}>
              <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5 + index / 2}>
                <div
                  className="align-self-center pt-1 pb-4 px-1 mb-2 stat-div"
                  // style={{ aspectRatio: "1/1" }}
                >
                  <h1
                    className="text-700 my-4"
                    id="count"
                    style={{ fontSize: size ? "70px" : "40px" }}
                  >
                    <CountUp end={c.value} />
                    <small style={{ color: "#ffffff" }}>+</small>
                  </h1>
                  <h5 className="text-400 my-0" style={{ fontSize: size ? "20px" : "12px" }}>
                    {c.title}
                  </h5>
                </div>
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default MainStats;

const content = [
  { value: "51", title: "C-Level/ Directors/VP’s" },
  { value: "28", title: "Head of Digital Banking/ Digital Transformation/ Multi Channel" },
  { value: "21", title: "General Managers/ Senior Managers/ Manager" },
];

const content1 = [
  { value: "90", title: "Established New Contacts And Generated New Business" },
  { value: "76", title: "Lanched Their Products At The Event " },
  {
    value: "87",
    title:
      "Recognised Finnovex Summit As Crucial For Entering Middle East, Africa And Europe Market",
  },
];
const content2 = [
  { value: "40", title: "Keynote Brilliance" },
  { value: "16", title: "Tailored Wisdom" },
  {
    value: "500",
    title: "Personalized Networking",
  },
  {
    value: "200",
    title: "Industry Confluence",
  },
];
