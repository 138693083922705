import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "rgba(255,255,255,0.05)",
        }}
        className="section"
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-700 text-center text-white">
                Finnovex Awards East Africa 2021 Winners
              </h2>
            </Col>
          </Row>
          <Row className="pt-5">
            {content.map((data) => (
              <Col lg={4} key={data}>
                <img
                  alt="..."
                  className="img rounded-0 img-responsive mb-2"
                  width="100%"
                  src={require(`assets/images/awards/${data}`)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Asset 48.jpg",
  "Asset 49.jpg",
  "Asset 50.jpg",
  "Asset 51.jpg",
  "Asset 52.jpg",
  "Asset 53.jpg",
  "Asset 54.jpg",
  "Asset 55.jpg",
  "Asset 56.jpg",
  "Asset 57.jpg",
  "Asset 58.jpg",
  "Asset 59.jpg",
  "Asset 60.jpg",
];
