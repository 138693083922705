import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/bootstrap.min.css";
// import 'assets/scss/paper-kit.scss';
import "assets/css/paper-kit.min.css";
import "assets/demo/demo.css";
import Home from "views/home.js";
import Award from "views/award.js";
import Sponsors from "views/sponsors.js";
import Register from "views/register.js";

import Delegates from "views/delegates.js";
import About from "views/about";
import Speakers from "views/speakers";
import DataProvider from "./DataContainer";
import PastSpeakers from "views/pastSpeakers";
import SponsorSingle from "views/SponsorSingle";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route path="/announcements" exact render={(props) => <About {...props} />} />
        <Route path="/awards" exact render={(props) => <Award {...props} />} />
        <Route path="/partners" exact render={(props) => <Sponsors {...props} />} />
        <Route path="/delegates" exact render={(props) => <Delegates {...props} />} />
        <Route path="/delegates/:id" exact render={(props) => <Delegates {...props} />} />

        <Route
          path="/request-brochure"
          exact
          render={(props) => <Register title="Request Brochure" {...props} />}
        />
        <Route
          path="/register"
          exact
          render={(props) => <Register title="Register" {...props} />}
        />

        <Route path="/me" exact render={(props) => <Home {...props} />} />
        <Route path="/me/:id" exact render={(props) => <Home {...props} />} />
        <Route path="/about" exact render={(props) => <About {...props} />} />
        <Route path="/sponsors/:sponsor" exact render={(props) => <SponsorSingle {...props} />} />
        <Route path="/past-speakers" exact render={(props) => <PastSpeakers {...props} />} />
        <Route path="/speakers" exact render={(props) => <Speakers {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
