import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import WhySponsor from "components/WhySponsor";
// import Sponsor from 'components/SponsorAll';

import { whosponsor } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function Sponsors() {
  return (
    <div id="outer-container">
      <div
        style={{
          background: "#000",
          // backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Navbar />
        <ScrollUpButton />
        <ProfilePageHeader title="Partners Finnovex 2023<br/>East Africa" />
        <WhySponsor whosponsor={whosponsor} />
        {/* <Sponsor sponsors={sponsors} title={'PAST SPONSORS & PARTNERS'} /> */}
        <Footer />
      </div>
    </div>
  );
}

export default Sponsors;
