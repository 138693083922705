import React, { useEffect } from "react";
import anime from "animejs";
import { size } from "./common";

function AnimeBackground() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 2000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: size ? "3rem" : "0",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: -100,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1280 720"
        style={{
          fill: "none",
          stroke: "#40b5cb66",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="st0"
          strokeWidth="5"
          d="M851.3,124.1c-0.2,0.2-0.4,0.4-0.6,0.7c-0.6,0.6-1.3,1.4-2.1,2.1c-0.2,0.2-0.4,0.3-0.6,0.5l0,0
	c-0.8,0.7-1.6,1.6-2.6,2.5l-0.6,0.6c-1.4,1.3-2.9,2.7-4.5,4.3l-0.1,0.1c-0.7,0.7-1.5,1.4-2.3,2.1l0,0c-1,0.9-2,1.9-3,2.8
	c-0.6,0.6-1.2,1.1-1.8,1.7l0,0c-0.2,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.4-0.6,0.6c-1.1,1-2.2,2.1-3.3,3.2c-0.4,0.4-0.8,0.8-1.2,1.2
	c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.8,0.7-1.1,1.1c-1.1,1.1-2.3,2.2-3.4,3.4c-0.7,0.6-1.4,1.3-2,2
	c-0.3,0.3-0.5,0.6-0.8,0.8c-1,0.9-1.8,1.8-2.7,2.7c-0.4,0.5-0.9,0.9-1.3,1.3c-1.3,1.4-2.6,2.8-3.8,4.2c-0.1,0.1-0.2,0.2-0.2,0.2
	c-1.7,1.8-3.1,3.5-4.5,5.2c-0.7,0.9-1.4,1.7-1.9,2.5h0c-0.1,0.1-0.1,0.2-0.2,0.3c-1.4,1.7-2.7,3.5-3.8,5.1v0.1c-1.7,2.5-3.1,4.9-4,7
	c-1,2.2-1.5,4.1-1.5,5.8c0,3.5-0.1,12.1-0.1,24.2c0,2,0,4,0,6.2c0,1.9,0,3.9,0,6c0,1.8,0,3.7,0,5.5c0,2,0,3.9,0,6
	c0,2.3,0,4.7-0.1,7.2c0,3.9-0.1,7.9-0.1,12c0,0.9,0,1.9,0,2.8c0,2.7-0.1,5.4-0.1,8.1v3.4h0c0,3.2-0.1,6.5-0.1,9.7c0,1.2,0,2.4,0,3.6
	c0,3,0,6-0.1,9c0,1.7-0.1,3.4-0.1,5.1c0,0.4,0,0.7,0,1.1h-0.3v0.4l0,5v2.3l0,4.4l0,5l0,7.4l0,6.9v0l0,4.8l0,3l0,2.2l0,4l0,9.2
	l-0.1,12.8l0,9l0,0.7l-0.1,15.4v0.9l0,5.9l0,1.7v0.6c0,1.7,0,3.3,0,4.9v0.5c0,1.8,0,3.5,0,5.1c0,0.3,0,0.7,0,1c0,1.6,0,3.2,0,4.6
	c0,0.1,0,0.2,0,0.3v0.3h0v7.8c0,0.5,0,1,0.1,1.5v0c0.1,0.6,0.2,1.2,0.4,1.9c1.1,5.2,4.6,10.5,9.6,15.7c0.9,1,1.9,1.9,2.9,2.9
	c0.8,0.7,1.6,1.5,2.4,2.2c0.6,0.5,1.2,1.1,1.9,1.6c0.9,0.7,1.7,1.5,2.6,2.2c0.2,0.2,0.5,0.4,0.7,0.6c0.9,0.7,1.8,1.4,2.7,2.1
	c-17,18.8-43.8,21.2-64.7,27.9c-2,0.7-4,1.4-5.9,2.1c-2.5,1-4.6,2.2-6.3,3.7c-0.5,0.4-1,0.9-1.4,1.4c-10,11.2-0.7,32.2-2.2,48.1
	c-0.1,1.3-0.3,2.6-0.6,3.9v0c0,0.2-0.1,0.5-0.2,0.7c-0.2,0.6-0.3,1.1-0.5,1.7c-0.4,1-0.9,2.3-1.5,3.8c-0.8,1.8-1.7,4-2.8,6.4
	c-0.4,0.9-0.9,1.8-1.3,2.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.8,1.7-1.6,3.5-2.6,5.4c-0.4,0.9-0.9,1.8-1.3,2.7c-1,2-2,4-3,6.2
	c-0.8,1.7-1.7,3.4-2.6,5.2c-0.3,0.5-0.6,1.1-0.8,1.7c-1.2,2.3-2.3,4.7-3.5,7.1c-0.1,0.3-0.3,0.6-0.4,0.8c-0.2,0.3-0.3,0.7-0.5,1
	c-0.7,1.3-1.3,2.6-2,3.9c0,0,0,0,0,0v0l-1,2.1l-0.1,0.1c-1.4,2.7-2.7,5.5-4.1,8.3c-0.3,0.5-0.6,1.1-0.9,1.7c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.7-0.7,1.3-1,2c-0.3,0.6-0.6,1.2-0.9,1.8c-0.9,1.8-1.8,3.6-2.7,5.4c-0.3,0.7-0.7,1.4-1.1,2.1
	c-1.5-0.7-3.1-1.3-4.6-2c-1.8-0.8-3.6-1.5-5.4-2.3c-0.6-0.2-1.1-0.5-1.7-0.7c-0.9-0.4-1.8-0.8-2.6-1.1c-3-1.3-6-2.6-9-3.9
	c-2.3-1-4.5-2-6.8-2.9c-11.8-5.1-22.7-10-31.4-14.2c-1.4-0.7-2.7-1.3-3.9-1.9h0l0,0c-1-0.5-2-1-2.9-1.5c-1.1-0.5-2.1-1.1-3.1-1.6
	c-1-0.5-1.9-1-2.7-1.5c-1.9-1.1-3.5-2-4.6-2.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c-1.2-0.9-1.9-1.6-1.9-2.1v-66.7
	c0-0.5-0.3-1.2-0.9-2c-0.1-0.2-0.2-0.4-0.4-0.5c-0.5-0.6-1.2-1.3-2-2c-0.3-0.2-0.6-0.5-0.9-0.8c-0.1-0.1-0.2-0.2-0.4-0.3
	c-0.6-0.5-1.2-1-1.9-1.5c-4.8-3.6-12-8-21-13c-1.1-0.7-2.3-1.3-3.5-2c-1.5-0.8-3-1.6-4.5-2.5c-1-0.6-2.1-1.1-3.1-1.7
	c-0.4-0.2-0.7-0.4-1.1-0.6c-1-0.5-2.1-1.1-3.1-1.7c-2-1.1-4-2.1-6.1-3.2c-1-0.5-1.9-1-2.9-1.5c-1-0.5-2-1-3-1.6
	c-1.5-0.8-3-1.6-4.6-2.3c-1.4-0.8-2.9-1.5-4.4-2.3c-0.1-0.1-0.3-0.1-0.4-0.2c-1.4-0.7-2.7-1.4-4.1-2.1c-3.3-1.7-6.6-3.3-10-5
	c-1.3-0.7-2.6-1.3-3.9-2c-0.4-0.2-0.8-0.4-1.3-0.6c-1.5-0.8-3.1-1.5-4.7-2.3c-0.3-0.2-0.6-0.3-0.8-0.4c-0.9-0.5-1.8-1-2.8-1.4
	c-1.9-0.9-3.8-1.9-5.7-2.8l0,0c-3-1.5-6-3-9-4.5l-2.6-1.3c-2.6-1.3-5.2-2.5-7.7-3.8l-5-2.4l-2.7-1.3l-3.8-1.8l0,0l-2-0.9l-6-2.9
	l-6.6-3.2l-0.7-0.4c-1.9-0.9-3.7-1.8-5.5-2.7l-0.8-0.4c-18.9-9.2-35.5-17.4-46.7-23.3c-4.2-2.2-7.6-4.1-10-5.6
	c-1.2-0.7-2.2-1.4-2.9-1.9c0,0,0,0,0,0c-0.5-0.4-0.9-0.7-1.2-1c-0.8-0.8-1.4-1.6-1.9-2.7c0,0,0,0,0,0c-0.1-0.3-0.3-0.6-0.4-0.9
	c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.5-0.4-1.1-0.6-1.6v-0.1c-0.1-0.3-0.2-0.7-0.3-1c0,0,0-0.1,0-0.1c-0.7-2.1-1.2-4.6-1.5-7.4v0
	c-0.7-5.2-1-11.5-0.7-18.3c0.1-4,0.4-8.2,0.8-12.5c1-10.3,2.9-21.2,5.3-31.3c1.8-7.7,4.1-15,6.7-21.2c0.5-1.3,1.1-2.6,1.7-3.8
	c2.2-4.4,5.2-8.6,8.5-12.7c9.8-12.1,22.8-23.2,31-35.7c3.6-4.3,5.6-9.1,6.6-14c0.1-0.4,0.2-0.8,0.2-1.3c0.7-4.3,0.7-8.6,0.2-12.7
	c-0.3-2.8-0.8-5.5-1.5-7.9c-0.7-2.9-1.6-5.5-2.4-7.6c-1.1-2.9-2-4.8-2.1-5c0,0,0,0,0,0c-0.3-0.9-0.7-1.9-1-2.8
	c-0.8-2.2-1.7-4.5-2.6-6.8l0,0c-0.4-1-0.8-2.1-1.2-3.2c-0.8-2-1.6-4.1-2.4-6.2c-0.9-2.3-1.8-4.6-2.7-6.9c-0.8-2.1-1.6-4.1-2.5-6.2
	c-0.8-2.2-1.7-4.3-2.6-6.5c-1-2.4-1.9-4.8-2.9-7.2c-3.6-9-7.3-17.9-10.6-26c-1-2.3-1.9-4.5-2.8-6.7c-0.2-0.5-0.4-1-0.6-1.5
	c-2.9-7.1-5.5-13.3-7.5-18c-0.3-0.7-0.6-1.4-0.8-2v0c-1.5-3.4-2.5-5.9-3-7c-0.2-0.5-0.3-0.8-0.3-0.8c1.3,0,2.8-0.1,4.5-0.4
	c1.9-0.3,4.2-0.8,6.6-1.4c1.7-0.4,3.5-0.9,5.3-1.5c1-0.3,2-0.6,3.1-1c2.1-0.7,4.4-1.4,6.6-2.2c1-0.3,2.1-0.7,3.1-1.1
	c1-0.4,2.1-0.7,3.1-1.1c1-0.4,2-0.7,2.9-1.1c1.7-0.6,3.3-1.2,5-1.9c0.9-0.4,1.9-0.7,2.8-1.1c1.5-0.6,2.9-1.1,4.4-1.7
	c1.1-0.4,2.1-0.8,3.2-1.3c1-0.4,2-0.8,3-1.2c1.1-0.4,2.1-0.9,3.2-1.3c1.7-0.7,3.3-1.3,4.8-2c1.2-0.5,2.4-1,3.5-1.5
	c1.6-0.7,3.1-1.3,4.2-1.8c1.6-0.7,2.8-1.2,3.4-1.5c0.4-0.2,0.5-0.2,0.5-0.2c0.6,0.5,1.3,1,1.9,1.6c1,0.8,2,1.7,3.1,2.6
	c1.6,1.4,3.3,2.9,5.1,4.6l0,0c1,0.9,2,1.9,3,2.9l0.1,0.1c0.7,0.7,1.5,1.5,2.2,2.2c0.3,0.3,0.6,0.5,0.8,0.8c0.9,0.8,1.9,1.7,3,2.5
	c1.2,0.9,2.5,1.9,3.9,2.9c0.6,0.4,1.3,0.9,2,1.3c0,0,0.1,0.1,0.1,0.1c0.7,0.5,1.5,1,2.3,1.4c1.4,0.9,2.8,1.7,4.3,2.6
	c1.4,0.8,2.9,1.6,4.4,2.4c1.5,0.8,3,1.6,4.6,2.4c0.5,0.3,1.1,0.6,1.6,0.8c1.3,0.6,2.5,1.3,3.8,1.9c0.5,0.2,1,0.5,1.5,0.7
	c1.1,0.5,2.2,1.1,3.3,1.6c1.2,0.6,2.5,1.1,3.8,1.7c0.4,0.1,0.7,0.3,1.1,0.5c1.3,0.6,2.6,1.2,3.9,1.8c0.6,0.2,1.2,0.5,1.8,0.7
	c1.3,0.6,2.5,1.1,3.8,1.7h0c0.1,0,0.1,0,0.2,0.1c1.9,0.8,3.9,1.6,5.8,2.4c3,1.2,6.1,2.4,9.2,3.6c2.7,1,5.5,2.1,8.2,3.1
	c1.5,0.6,3.1,1.1,4.7,1.7c1.6,0.6,3.2,1.1,4.8,1.7c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0.2,1.1,0.4,1.6,0.6h0c8.4,2.9,16.8,5.6,25.2,8.2
	c1.5,0.4,2.9,0.9,4.4,1.3c0.5,0.2,1,0.3,1.6,0.5c1.3,0.4,2.6,0.8,3.9,1.1c0,0,0,0,0,0c2.4,0.7,4.8,1.4,7.1,2c3.7,1,7.4,2,11,3
	c0,0,0,0,0,0c3.3,0.8,6.5,1.6,9.6,2.3c0.5,0.1,1.2,0.3,1.8,0.4c3.7,0.8,7.4,1.6,10.9,2.3c1.6,0.3,3.1,0.6,4.6,0.8
	c3.4,0.7,6.7,1.2,9.8,1.7c0.2,0,0.3,0.1,0.5,0.1c1,0.1,2.1,0.3,3.1,0.4c2.7,0.3,5.3,0.4,7.8,0.5c2.5,0,4.8,0,7.2-0.2
	c1.3-0.1,2.4-0.2,3.6-0.3c1.5-0.1,2.9-0.3,4.3-0.6c2.3-0.3,4.5-0.8,6.7-1.4c1.4-0.3,2.8-0.8,4.1-1.2c1.6-0.5,3.2-1.1,4.7-1.7h0
	c2.7-1,5.3-2.2,7.8-3.4c2.6-1.3,5.1-2.7,7.5-4.1c1.2-0.7,2.3-1.3,3.5-2.1c0.3-0.2,0.7-0.4,1-0.7c2.3-1.4,4.4-2.9,6.5-4.3
	c2.8-1.8,5.4-3.6,7.9-5.3c0.1,0,0.1-0.1,0.2-0.1c0.6-0.3,1.1-0.6,1.6-0.9h0c2.3-1.1,4.6-1.2,6.8-1c1.6,0.2,3.2,0.5,4.7,0.9h0
	c2.1,0.5,4.2,1,6.3,1H851.3z"
        />
        <path
          class="st0"
          d="M423.9,99.1C423.9,99.1,423.9,99.1,423.9,99.1l-5.8-0.7l-0.7-0.1h0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-0.4,3l-0.4,3v0
	c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2h0l5.8,0.7l0.7,0.1h0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0.1-0.1l0.1-0.6l0.7-5.3
	C424.1,99.2,424,99.1,423.9,99.1z"
        />
        <path
          class="st0"
          d="M616.6,258.1C616.6,258.1,616.6,258.1,616.6,258.1c0.2,0.2,0.5,0.2,0.8,0.2c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.5
	c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1c0.2-0.3,0.3-0.5,0.3-0.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3
	c-0.1-0.1-0.2-0.2-0.3-0.3l0,0c-0.5-0.3-1.1-0.2-1.6,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1-0.1,0.1
	c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2,0.1,0.4,0.1,0.5C616.4,257.8,616.5,258,616.6,258.1z"
        />
        <path
          class="st0"
          d="M511.7,318.6c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.2,0.2,0.5,0.4,0.8,0.5
	c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0.3,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.2,0.4-0.4
	c0.3-0.4,0.5-1.1,0.4-1.7c0,0,0,0,0,0c0-0.2-0.1-0.4-0.2-0.6s-0.2-0.3-0.3-0.5c0,0,0,0,0,0c-0.3-0.4-0.8-0.6-1.3-0.6
	c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.1-0.3,0.2-0.4,0.4C511.8,317.3,511.6,317.9,511.7,318.6
	C511.7,318.6,511.7,318.6,511.7,318.6z"
        />
        <path
          class="st0"
          d="M565.6,153.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.2-0.5
	c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4,0-0.6c-0.1-0.6-0.4-1.1-0.8-1.5c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.6-0.3-1.2-0.3-1.9-0.2c0,0,0,0,0,0c-0.7,0.1-1.2,0.4-1.7,0.8c-0.2,0.1-0.3,0.3-0.4,0.4c-0.5,0.6-0.7,1.4-0.6,2.2
	c0,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.4,0.7,0.7,1c0.7,0.7,1.8,1,2.9,0.8c0,0,0,0,0,0C564.9,153.7,565.2,153.6,565.6,153.4z"
        />
        <path
          class="st0"
          d="M547.9,127.5C547.9,127.5,547.9,127.5,547.9,127.5l3.5,5.1c0.1,0.1,0.2,0.2,0.4,0.1l12.3-4.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1l-3.8-5.2
	c-0.1-0.1-0.2-0.2-0.4-0.1l-11.9,4.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	C547.9,127.4,547.9,127.4,547.9,127.5C547.9,127.4,547.9,127.5,547.9,127.5C547.9,127.5,547.9,127.5,547.9,127.5z"
        />
        <path
          class="st0"
          d="M638.3,541.6l2.4,4.2c0.1,0.2,0.3,0.2,0.5,0.2c15.2-6.7,30.1-14.1,44.6-22c0.3-0.2,0.4-0.5,0.3-0.9l-1.7-5.1
	c-0.1-0.2-0.2-0.2-0.3-0.1L639,539.6c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C638.3,541.4,638.3,541.4,638.3,541.6
	C638.3,541.5,638.3,541.5,638.3,541.6z"
        />
        <path
          class="st0"
          d="M624.6,551.3L624.6,551.3l0.1,7.9c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1l6.9-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1v0
	l-0.1-7.9c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0l-6.9,0.1C624.7,551.1,624.6,551.2,624.6,551.3z"
        />
        <path
          class="st0"
          d="M633.5,520.7C633.5,520.7,633.5,520.7,633.5,520.7l0.8-10.6l0.1-0.8v0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2l-7-0.5l-0.6,0
	l0,0c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.1v0l-0.9,11.4c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0l7.5,0.6
	C633.4,521,633.5,520.9,633.5,520.7z"
        />
        <path
          class="st0"
          d="M632.8,524.8C632.8,524.8,632.8,524.8,632.8,524.8c-0.1-0.1-0.1-0.1-0.1-0.1l-7.1-0.4c-0.1,0-0.2,0.1-0.2,0.2l-0.5,8.7
	c0,0,0,0.1,0,0.1s0.1,0.1,0.1,0.1l7.1,0.4c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0L632.8,524.8
	C632.9,524.9,632.8,524.8,632.8,524.8z"
        />
        <path
          class="st0"
          d="M623.2,456.2C623.2,456.2,623.2,456.2,623.2,456.2l-3.3-0.2l-3.1-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-0.3,5.9v0
	c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.1h0l3.3,0.2l3.1,0.2c0.1,0,0.2-0.1,0.2-0.2l0.3-5.9C623.4,456.3,623.3,456.2,623.2,456.2z"
        />
        <path
          class="st0"
          d="M643.9,349.1L643.9,349.1c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.2,0,0.5,0.1,0.7c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.4,0.6,0.7,0.7
	c0.9,0.4,1.9,0,2.4-1c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.4c0.1-0.5,0.1-1-0.1-1.4c-0.1-0.2-0.2-0.4-0.4-0.5c0,0-0.1,0-0.1-0.1
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2-0.1-0.5-0.1-0.7,0C644.7,348.1,644.2,348.5,643.9,349.1z"
        />
        <path
          class="st0"
          d="M815.2,137.1C815.2,137.1,815.2,137.1,815.2,137.1l-0.2-1.5c0-0.1-0.1-0.2-0.2-0.2l-5.9-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1l0.6,1.7c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.8-0.4
	c0,0,0.1,0,0.1,0l4.5,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
	C815.2,137.2,815.2,137.2,815.2,137.1C815.2,137.2,815.2,137.1,815.2,137.1C815.2,137.1,815.2,137.1,815.2,137.1z"
        />
        <path
          class="st0"
          d="M703.6,287.3c1.7,0,3.1-1.4,3.1-3.1c0-0.2,0-0.4-0.1-0.6c-0.1-0.6-0.4-1.1-0.8-1.5c-0.6-0.6-1.3-0.9-2.2-0.9
	c-1.7,0-3.1,1.4-3.1,3.1c0,0,0,0,0,0C700.6,285.9,702,287.3,703.6,287.3L703.6,287.3z"
        />
        <path
          class="st0"
          d="M747.5,269.7l-7.2-7.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0l-3.4,3.3
	c-0.1,0.1-0.1,0.2,0,0.3l5.5,7.5c0.1,0.1,0.2,0.1,0.3,0l5-3.7c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
	C747.6,269.8,747.6,269.8,747.5,269.7C747.6,269.7,747.6,269.7,747.5,269.7C747.5,269.7,747.5,269.7,747.5,269.7z"
        />
        <path
          class="st0"
          d="M759.5,402.2c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3l-0.4,1.3l-0.9,2.6c0,0,0,0,0,0
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.4c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0,0.1,0.1,0.2,0.1l3.7,1.3c0.1,0,0.3,0.1,0.4,0
	c0.1,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3l1.3-3.9c0-0.1,0.1-0.3,0-0.4c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.4l-3.7-1.3C759.9,402.1,759.7,402.1,759.5,402.2z"
        />
        <path
          class="st0"
          d="M448,120.9C437,114,430.5,104,429.8,91.5c1-0.3,2-0.6,3.1-1c2.1-0.7,4.4-1.4,6.6-2.2c0,1.2,0.1,2.3,0.2,3.4
	c0.9,12.3,6.5,18.3,14.9,26.4c0.2,0.2,0.4,0.6,0.4,0.9C455.4,123.8,450.6,122.5,448,120.9z"
        />
        <path
          class="st0"
          d="M470.9,123.5c-1.9,3.4-6,1.5-8.4-0.7c-2.5-2.3-4.3-4.7-6.5-7.8c-6.1-8.8-9.5-18.4-10.4-28.9c1-0.4,2-0.7,2.9-1.1
	c1.7-0.6,3.3-1.2,5-1.9c0.4,3.9,1.3,7.8,2.6,11.8c2.9,8.8,7.2,17.3,13.7,23.8C471.2,120.2,472,121.7,470.9,123.5z"
        />
        <path
          class="st0"
          d="M543.2,106c-0.6,0.3-1.2,0.5-1.9,0.8c-0.1,0-0.2,0.1-0.3,0.1c-2.8,1.2-5.5,2.4-8.2,3.6c-14.8,6.5-36.4,14.8-51.7,6.5
	c-12.5-6.8-19.8-21.9-20.4-35.7c0-0.3,0-0.6,0-0.9c1.1-0.4,2.1-0.8,3.2-1.3c1-0.4,2-0.8,3-1.2c0.4,7.4,3.4,15.5,7.2,21.3
	c12.8,19.6,33.4,14.4,51.4,6.7c2.4-1,4.8-2,7.1-3.1c0.1,0,0.1-0.1,0.2-0.1c0.6-0.2,1.1-0.5,1.7-0.7c1.6,0.7,3.2,1.5,4.9,2.2
	C540.6,104.9,541.9,105.5,543.2,106z"
        />
        <path
          class="st0"
          d="M524.2,97.1c-0.5,0.2-1,0.4-1.4,0.7c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-1.9,0.9-4.7,1.8-8.4,2.9c-13.6,3.9-25,2.4-34.2-9.3
	c-3.9-4.9-5.3-10.7-5.2-16.7c1.2-0.5,2.4-1,3.5-1.5c1.6-0.7,3.1-1.3,4.2-1.8c0,1.5,0,3,0.1,4.5c0.7,10.9,7.4,19.4,18.7,19.6
	c4,0.1,8.4-1.1,12.6-2.8c0,0,0,0,0,0c0.4-0.1,0.8-0.3,1.2-0.5C518,93.9,521,95.5,524.2,97.1z"
        />
        <path
          class="st0"
          d="M500.4,81.7L500.4,81.7c-0.2-0.1-0.4-0.2-0.6-0.3c-1.4-0.7-2.4-1.7-3.1-3.1l0,0c-1.3-1.3-2.6-2.5-3.9-3.7c0,0,0,0-0.1-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-1.4-1.2-2.7-2.4-4-3.5c0,0.1,0,0.2,0,0.3c0.3,8.5,4.1,15.8,14.9,15.9
	c1.1,0,2,0,2.6,0L500.4,81.7z"
        />
        <path
          class="st0"
          d="M584.1,121.8c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0-0.3,0-0.4-0.1l-0.5-0.7C583,121.5,583.5,121.7,584.1,121.8z"
        />
        <path
          class="st0"
          d="M780.6,124.7c-10.8,11.3-18,27.2-23,40.7c-0.6,1.6-1.5,2.9-2.9,3.9c-0.2,0.1-0.4,0.2-0.6,0.2c-4.6-0.5-3.6-4.9-2.4-8.5
	c4.2-13.3,12-25.8,21.2-36.3c0.4,0,0.7,0,1.1,0H780.6z"
        />
        <path
          class="st0"
          d="M551.9,111.4l-10.5,4.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l4.3,5.7
	c0.1,0.1,0.1,0.1,0.2,0.1l10.8-4.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-4.6-5.9
	C552.1,111.4,552,111.4,551.9,111.4z"
        />
        <path
          class="st0"
          d="M580.1,123C580.1,123,580.1,123,580.1,123C580,123,580,123.1,580.1,123c0,0.1,0,0.1-0.1,0.2v0c0,0-0.1,0-0.1,0l-12.5,3.9
	c-0.1,0-0.2,0-0.3-0.1l-4.3-5.3c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0.1,0l6.5-2.6c3.4,1.3,6.8,2.6,10.3,3.8l0.2,0.2c0,0,0,0.1,0,0.1C580,122.9,580,122.9,580.1,123
	C580.1,122.9,580.1,122.9,580.1,123z"
        />
        <path
          class="st0"
          d="M538.1,117.6l-9.8,4.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
	c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l3.1,5.7c0.1,0.1,0.2,0.2,0.3,0.1l10.8-4.3c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1l-4.1-5.9
	C538.4,117.5,538.2,117.5,538.1,117.6z"
        />
        <path
          class="st0"
          d="M431.8,125.9l-3.2,2.4c-0.1,0.1-0.3,0.2-0.3,0.4c-1.3-3.1-2.5-6-3.7-8.8c2.6,1.5,5.1,3.3,7.2,5.4
	C432.1,125.5,432.1,125.7,431.8,125.9z"
        />
        <path
          class="st0"
          d="M489.8,167.4c-5.9,4.9-12,4.4-19.1,3.7c-1.9-0.2-3.4,0.5-4.6,1.9c-4.2,5.2-8.9,10.1-14.8,12.9c-0.8-2.1-1.6-4.2-2.5-6.3
	c3.2-1.6,6.2-3.6,8.8-6.4c4.1-4.4,6.2-8.3,12.8-7.9c6.9,0.4,13.5,1.7,18.2-4.1c8.3-10.3-0.1-28.1-7.2-36.5c-0.9-1-0.8-2.8,0.7-3.2
	c1.9-0.5,4.3,1.4,5.5,2.8C497.8,135.6,503.5,156,489.8,167.4z"
        />
        <path
          class="st0"
          d="M522,166.2c-2.1,4.3-4.3,7.9-5.5,12c-0.7,2.3-1.4,4.6-2.2,6.7c-7.4,17.5-25.2,29.4-44.4,24.8c-3.8-0.9-7.4-2.2-11-3.7v0
	c-0.9-2.4-1.8-4.9-2.8-7.5c13,6.3,24.1,7.8,37.3-0.4c10.9-6.8,14.2-18.6,19.3-29.5c2.7-5.7,4.3-9.6,4.8-11.7
	c2.8-10.4,0.4-20.5-6.4-29.1c-1.4-1.8-3.7-5-0.6-6.2c1.6-0.6,3-0.2,4.2,1.2C525.8,134.9,529.4,150.9,522,166.2z"
        />
        <path
          class="st0"
          d="M465.1,198.1c1,0.7,1.6,1,2,1.1c12.3,1.4,21.2-2.9,26.8-13.1c5.2-9.5,9.4-16.8,12.4-22c2.1-3.5,3.4-6.2,4-8
	c3.4-11.1-2-23-8.6-32.1c-1.6-2.1-3.9-2-6-0.8c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.1
	c7.3,11.1,13.5,24.5,5.7,37.2c-3.7,6-7.1,12-10.4,18.2c-6,11.5-11.4,12.6-23.6,14.7c-1.5,0.3-2.2,1.1-2.1,2.5
	C464.9,197.8,465,198,465.1,198.1z"
        />
        <path
          class="st0"
          d="M436.4,123.3c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c7.7,5.8,14.3,9.4,14,20.4c0,1-0.4,2.3,0,3.5
	c0.7,2.6,3.2,3,5.1,1.1c4.2-4.4,2.6-14.3-0.9-18.6C450.5,125.8,442,123.7,436.4,123.3z"
        />
        <path
          class="st0"
          d="M433,126.4c-0.2-0.2-0.5-0.2-0.7,0c-1.1,1.3-2.3,2.3-3.8,3.2c-0.3,0.2-0.3,0.3-0.1,0.5c3,2.7,5.7,5.7,8.1,9
	c0.1,0.1,0.2,0.2,0.4,0.1c1.6-1,3.4-1.6,5.2-1.9c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1C439.7,133,436.5,129.5,433,126.4z"
        />
        <path
          class="st0"
          d="M545.6,128.3c0-0.1-0.1-0.1-0.2-0.1l-11.5,4.3c-0.2,0.1-0.3,0.2-0.2,0.5l2.7,5c0.1,0.3,0.4,0.4,0.7,0.3l13.2-5
	c0.1,0,0.1-0.1,0-0.1C548.2,132.1,546.6,130.5,545.6,128.3z"
        />
        <path
          class="st0"
          d="M485.4,151.5c1.3-4.2-4.3-20.9-8.4-22.9c-0.2-0.1-0.4-0.1-0.6-0.1c-4,0.9-2.7,5-2,7.6c1.4,5,2.6,10.1,3.8,15.2
	C479.2,155.2,484.2,155.5,485.4,151.5z"
        />
        <path
          class="st0"
          d="M607.3,138.1c-3.7-9-13.3-11.9-21.8-8c-9.1,4.2-11.7,14.8-9.9,23.9c0.1,0.5-0.1,0.8-0.6,0.9c-5.8,1.3-11.3,3.2-16.6,5.8
	c-0.2,0.1-0.4,0-0.4-0.2c-0.3-6.7-5-9-11.2-8.4c-16.6,1.6-22.3,22.5-23.3,36c0,0.2-0.1,0.4-0.3,0.5c-5.7,3.5-16,10.8-12.9,18.8
	c2.4,6.2,10.4,8.2,16.5,7.5c13.7-1.7,26.3-5,39.1-8.9c15.4-4.7,29.1-12.4,35.7-27.4C606.5,167.5,612,149.5,607.3,138.1z
	 M530.7,204.4c-1.5,8.3-19,3.6-13-3.5c2.1-2.5,4.6-4.1,7.6-4.8C530.3,194.8,531.3,201,530.7,204.4z M545,164.7c4.9,1,5.4,7,4.3,11.1
	c-1.7,6.6-7.3,11.3-14,12.1C524.6,189.2,533.5,162.4,545,164.7z M552.8,200.6c-3.5,4.5-8.3,6.8-14.2,6.8c-1.1,0-2.4-1.3-2.4-2.6
	c-0.1-3.4,1.2-6.5,3.8-9.5c2.4-2.7,11-9.7,14.5-5.3C556.7,192.9,554.8,198.1,552.8,200.6z M555.9,175.5c0.5-7.9,7.8-14.3,15-16.6
	c3.4-1,8-1.5,8.6,3.2c1.2,9.2-10.2,14.8-17.2,17.2C558.9,180.5,555.6,179.9,555.9,175.5z M592.6,180.8c-2.4,8.7-12.9,13.8-20.8,16
	c-4.6,1.3-9.2-1.8-8.4-6.8c1.3-8,16.8-17.5,24.4-17.2C592.5,172.9,593.7,176.9,592.6,180.8z M587.8,155.7c-0.2,0.1-0.4,0-0.5-0.2
	c-5.5-8.7,10.1-23.7,13.6-10.5C603,152.7,593.1,154.6,587.8,155.7z"
        />
        <path
          class="st0"
          d="M824.4,129.1C824.4,129.1,824.4,129.1,824.4,129.1C824.4,129.1,824.4,129.1,824.4,129.1c0-0.1,0-0.1,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-7.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3,3.6c0,0.1,0.1,0.3,0.3,0.3l6.2,0.6
	c0.2,0,0.3-0.1,0.3-0.3L824.4,129.1z"
        />
        <path
          class="st0"
          d="M814.8,132.4l-0.4-3.5c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.4,0.1c-2.1,0.1-4.2,0.2-6.3,0.4c-0.3,0-0.4,0.2-0.4,0.5
	l0.7,2.9c0.1,0.3,0.2,0.4,0.5,0.4l6.4-0.2C814.7,132.6,814.8,132.5,814.8,132.4z"
        />
        <path
          class="st0"
          d="M833.6,130.5C833.6,130.5,833.6,130.5,833.6,130.5c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1l-5.8-0.8c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l-0.2,3.4
	c0,0.2,0.1,0.5,0.4,0.5l4.2,1c0.2,0.1,0.5,0,0.6-0.3l1.8-3.7C833.6,130.7,833.6,130.6,833.6,130.5z"
        />
        <path
          class="st0"
          d="M797.5,135.2l7-1.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.9-3.5c-0.1-0.3-0.2-0.4-0.5-0.3l-7.1,1.4c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0l3,12.7
	c0.1,0.2,0.2,0.3,0.4,0.3l7.3-0.5c0.2,0,0.3-0.1,0.2-0.3l-1.5-8.5c0-0.2-0.3-0.4-0.5-0.4l-7.1,0.5c-0.2,0-0.3-0.1-0.3-0.3
	C797.3,135.4,797.4,135.2,797.5,135.2z"
        />
        <path
          class="st0"
          d="M753,132.1l-3.1-2.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0l-3.2,5.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0l3.5,1.6
	c0.3,0.1,0.6,0,0.8-0.2l2.8-4.5C753.3,132.6,753.2,132.3,753,132.1z"
        />
        <path
          class="st0"
          d="M668.4,147.4c-4-0.7-8.2-1.6-12.6-2.5c-7.5,4.9-11.5,13.7-10.4,23.7c0.4,3.3,3.7,4.8,4.5,7.9c1.6,6.1-8.4,10-12.9,11.5
	c-0.2,0.1-0.3,0.2-0.4,0.4c-1,5-2.5,11.9-7.8,14.6c-0.2,0.1-0.3,0.3-0.1,0.5c3.2,5.7,6.2,10.2,7.1,17c0.6,4.7-0.9,7-5,9.1
	c-3.2,1.6-14,8.5-17.1,5.4c-4.4-4.4-8.8-8.8-13.2-13.3c-0.2-0.2-0.1-0.3,0.1-0.4c17.3-8.3,24.8-22.8,32.4-39.3
	c4.1-9,7.6-16.7,9.1-25c1.2-6.4,0.1-11.5-2.5-15.9c-3.4-0.9-6.9-1.8-10.4-2.8c1,1,2,2.1,3.1,3.3c4.1,4,3.7,8.9,1.7,13.8
	c-3,7.7-6.3,15.4-9.8,22.9c-8,17.4-17.2,38.5-38.8,41.2c-0.2,0-0.3,0.2-0.2,0.4c1,1.7,2.2,3.3,3.5,4.7c6.7,7.1,14.2,13.2,22.5,18.4
	c3.1,1.9,6.4,3,9.9,3.4c7.6,0.8,6.9,11.4,3.7,16c-0.9,1.3-4.5,5-3.2,6.5c2.4,2.9,7.5-1.7,8.8-3.7c5.2-8.2,2.7-17.9-1.7-25.8
	c0,0,0-0.1-0.1-0.2v-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1,0
	c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c6.2,0.5,14.9,1.7,14.5-7.7c-0.3-6.1-1.6-11.9-3.9-17.7c-0.1-0.2,0-0.3,0.2-0.3
	c9.4-0.6,15.5-5.5,18.2-14.5c2-6.8,0.9-11.9-0.5-18.6c0-0.2,0-0.4,0.2-0.5c5.2-2.4,8.8-6.1,11-11.2c2.6-6.2,4.2-12.7,4.7-19.6
	C672.1,148.1,670.3,147.8,668.4,147.4z M650.4,201.6c-2.1,0.8-5.7,0.7-7-1.5c-3.2-5.2,7.8-16.9,10.9-7.3
	C655.5,196.5,654.1,200.2,650.4,201.6z M660.1,170.2c-5,1.2-8.9-2.5-9.1-7.5c-0.1-3.2,0.8-6.2,2.6-8.7c3.8-5.3,13-4.1,13.1,3.3
	C666.9,161.7,665.1,169,660.1,170.2z"
        />
        <path
          class="st0"
          d="M745.9,145l-1.6,5c-0.1,0.3-0.3,0.4-0.6,0.3c-8.3-3.4-14.8-5.9-19.6-7.3c2.4-1.1,4.8-2.2,7-3.5c4.9,1.5,9.7,3.1,14.5,4.9
	C745.8,144.5,745.9,144.8,745.9,145z"
        />
        <path
          class="st0"
          d="M459.9,163.4c-3.3,2-9,4.5-12.9,7.7c-0.4,0.3-0.7,0.6-1.1,0.9c-0.8-2-1.6-4.1-2.4-6.1c3.6-2.5,7.9-4.6,11.1-6.7
	c9.6-6.1,10.5-15,8.4-25.5c-0.3-1.8,1.9-2.7,3.2-2.3c1,0.3,1.7,1.6,2.1,2.5C472.6,145.1,470.3,157.1,459.9,163.4z"
        />
        <path
          class="st0"
          d="M835.4,136.2C835.4,136.2,835.4,136.3,835.4,136.2C835.4,136.3,835.4,136.3,835.4,136.2c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l2.6,0.6c0.1,0,0.2,0,0.3-0.1l2.8-3.6c0,0,0,0,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0l-3.4-1.4
	c-0.1-0.1-0.3,0-0.4,0.2L835.4,136.2C835.4,136.2,835.4,136.2,835.4,136.2z"
        />
        <path
          class="st0"
          d="M792.1,133.1C792.1,133,792.1,133,792.1,133.1C792.1,133,792.1,133,792.1,133.1c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0
	c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0l-6,2.6c-0.3,0.1-0.4,0.3-0.3,0.7l4.5,10.7
	c0.1,0.2,0.3,0.3,0.5,0.3l5.4-1.5c0.3-0.1,0.3-0.2,0.2-0.5C794.5,141.2,793.2,137.2,792.1,133.1
	C792.2,133.1,792.1,133.1,792.1,133.1z"
        />
        <path
          class="st0"
          d="M529.8,145.7l0.4,7.2c0,0.2,0.1,0.3,0.3,0.2c10.4-3.7,21-6.9,30.9-11.9c4.8-2.4,3.7-9.3-1.4-7.7
	c-8.6,2.7-18.6,6.6-29.9,11.7C529.9,145.3,529.8,145.5,529.8,145.7z"
        />
        <path
          class="st0"
          d="M618.1,183.4c2.8-6,6.8-14.5,12-25.3c0.9-1.9,1.7-4.2,1.3-5.9c-1.1-5-5.6-14.6-10.5-17.1c-2.4-1.2-5-1-7.9,0.7
	c-0.2,0.1-0.3,0.3-0.2,0.6l1.9,4.1c0.1,0.2,0.3,0.4,0.5,0.4c5.6,1.2,8.4,4.6,8.3,10.3c-0.1,6.5-5.1,8.4-10.3,10.4
	c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.3c1.7,2,3.5,4,4,6.5c0.9,4.4-1.1,10.4-6,11.4c-2,0.4-3.9,0.7-5.8,0.9
	c-0.5,0.1-0.9,0.5-0.9,1c0.1,2.1,0.3,4.1,0.7,6.1c1.8,10.8-14.5,12.1-21,14.1c-9.6,3-18.7,6-28.5,7.9c-0.3,0.1-0.8,0.3-1.4,0.8
	c-0.2,0.1-0.3,0.3-0.3,0.5c-0.8,4.6,7.6,3.2,10,2.8c14-2.2,29.4-4,40.9-12.9C610.6,197.7,614.8,190.6,618.1,183.4z"
        />
        <path
          class="st0"
          d="M823.5,138c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0.2-1.4c0-0.1-0.1-0.2-0.2-0.2l-3-0.4l-3-0.4c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1v0l0,0.2L817,137c0,0.1,0.1,0.2,0.2,0.2l5.3,0.7l0.8,0.1c0,0,0,0,0,0
	C823.4,138,823.4,138,823.5,138C823.5,138,823.5,138,823.5,138z"
        />
        <path
          class="st0"
          d="M825.8,137.1c-0.9,1.9,1.8,2.4,3.1,2.5c0.2,0,0.4-0.1,0.5-0.3l0.6-0.9c0.2-0.3,0.1-0.5-0.2-0.6l-3.3-1
	C826.2,136.8,826,136.9,825.8,137.1z"
        />
        <path
          class="st0"
          d="M780.9,138.6C780.9,138.5,780.9,138.5,780.9,138.6c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
	l-5.9,4.1c-0.1,0.1-0.1,0.2-0.1,0.2l3.8,8.6c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	l6.6-3c0.1,0,0.1-0.2,0.1-0.2L780.9,138.6C780.9,138.6,780.9,138.6,780.9,138.6z"
        />
        <path
          class="st0"
          d="M440.8,159C440.7,159,440.7,159.1,440.8,159c-1.1-2.5-2.2-5.2-3.2-7.8c1.4-2.8,1.9-5.9,0.9-9.5c-0.1-0.2,0.1-0.5,0.3-0.6
	l4.6-2.3c0.2-0.1,0.5-0.1,0.6,0.1C449.2,144.1,445,154.4,440.8,159z"
        />
        <path
          class="st0"
          d="M831.9,141.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1l1.6,1c0.3,0.2,0.7,0.1,0.9-0.2l1.1-1.4
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l-1.5-0.7c-0.3-0.1-0.6-0.1-0.8,0.1l-1.1,1.1c0,0-0.1,0.1-0.1,0.1
	c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C831.9,141.3,831.9,141.4,831.9,141.4z"
        />
        <path
          class="st0"
          d="M677.6,214.8c1.7,2.9,6,8.2,2.6,10.4c-2.2,1.4-6.7-1.4-8.9-2.5c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2
	c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v0.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0.1,0.2
	c1.3,2.2,3.6,4.9,3.4,7.8c0,0.2-0.1,0.4-0.3,0.5c-2,1.8-8.4,1.2-11.2,1.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1v0.2c0,0.1,0,0.1,0.1,0.2c1.3,2.1,2.6,4.4,4,6.6
	c3.1,5-0.6,8.6-5.7,7.8c-1.4-0.2-7.4-2-8-0.5c-0.1,0.2-0.1,0.4,0.1,0.6c1.7,2,5.2,5.1,6.6,8.1c1.5,3.2,0.1,6.8-3.6,7.3
	c-2.5,0.3-6.1-1.1-9-1.6c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0.1-0.1,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0.1c0,0.1,0,0.1,0,0.2c2,4.3,4.9,10.2-2.1,12.3c-2.4,0.7-5.9-0.5-8.6-0.9
	c-0.4-0.1-0.5,0.1-0.3,0.4c1.3,3.6,4.1,7.1-0.2,10.2c-3.8,2.8-13.1,1.3-12,8.1c0.9,6,1.3,12.2,1,18.6c0,0.5,0.2,0.7,0.7,0.6
	c2-0.3,4.1-0.6,6.4-0.7c6.9-0.4,13.8-0.9,20.9-1.3c0.3,0,0.5,0.2,0.5,0.5l0.2,2.7c0,0.2-0.1,0.3-0.3,0.3l-28.2,1.1
	c-0.2,0-0.4,0.1-0.4,0.4l-0.3,5.5c0,0.3,0.1,0.4,0.4,0.4c9.8-0.6,19.6-0.8,29.4-0.5c0.2,0,0.4,0.1,0.5,0.3l1.8,2.9
	c0.2,0.3,0.1,0.4-0.2,0.4c-10.8-0.2-21.5,0-32.3,0.8c-0.4,0-0.6,0.2-0.6,0.6c-0.1,3.1-0.5,6.3-1.1,9.6c-0.9,5.1-6.9,4.3-6.2-1.2
	c1.1-9.7,1.3-19.4,0.4-29.2c-2-21.6-15.8-40.5-24.1-60.3c-2.3-5.5,3.5-8.1,6.2-3.2c9.8,17.4,17.6,30.9,23.4,48.2c0,0,0,0.1,0.1,0.1
	c0,0,0,0,0,0h0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0.5-2.5,1.6-4.7,3.2-6.5c1.7-2,5-3.4,6.3-5.9c0.7-1.3,0.2-3.8,0.2-5.2
	c0-0.6,0.4-1.1,1-1.3c2.6-1,6.4-2.2,7.9-4.9c1.5-2.7,3.1-5.4,4.8-8.1c1.5-2.4,1.7-4.9,0.7-7.4c-0.8-1.9-3-3.1-4.5-4.6
	c-0.2-0.2-0.2-0.4-0.2-0.6c0.5-3.4,13.5-1.2,14.8-10.8c0.6-3.9,0.9-9.1,2.5-12.3c1.9-3.7,5.4-6,8.5-8.6c1.5-1.3,1.4-2.6,0.5-4.4
	c-1.2-2.6-2.7-5.3-3.1-8c-1.3-8,1.1-16.4,3.8-23.5c3.2-8.3,6.1-16.8,8.6-25.4c0.3-0.9,0.6-2.2,1-3.6c0.7,0.1,1.4,0.2,2.1,0.3
	c1.8,0.2,3.6,0.4,5.3,0.6c-3.4,8.3-6.4,17.1-8.9,26.4C671.4,189.7,670.5,202.2,677.6,214.8z"
        />
        <path
          class="st0"
          d="M814.8,140.5l-4.5-0.3c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0.1l0.9,3.9c0,0.1,0.1,0.2,0.2,0.2l3.8,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-4C815,140.6,814.9,140.5,814.8,140.5z"
        />
        <path
          class="st0"
          d="M823.1,142l-4.5-0.7c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1l0.1,3.7c0,0.1,0.1,0.3,0.3,0.3l4,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
	c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.3-3.7
	C823.4,142.2,823.3,142,823.1,142z"
        />
        <path
          class="st0"
          d="M772.9,245.7c-7.3-14.3-13.4-29.1-18.2-44.5c-4.7-15.2-8.6-29.9-19.1-42.3c-5.2-6.1-11.4-11-18.8-13.2
	c-0.5,0.2-0.9,0.3-1.4,0.5c-1.3,0.4-2.7,0.9-4.1,1.2c-2.2,0.6-4.4,1.1-6.7,1.4c-1.4,0.3-2.8,0.5-4.3,0.6c-1.2,0.1-2.3,0.2-3.6,0.3
	c-1.1,0.1-2.3,0.2-3.4,0.2c-0.8,0.8-1.6,1.7-2.4,2.6c-2,2.5-3.8,6.3-5.6,11.3c-5.5,15.2-7.5,31.5-4.1,47.5
	c1.4,6.5,3.5,14.5,6.5,24.1c3.5,11.3,12.4,10.1,22,8.5c0.2,0,0.4,0,0.6,0.1c3.4,2,6.1,2.8,9.9,3.7c3.5,0.9,4.9,2.2,7.7,4.4
	c2.5,2,5.5,3,9.2,3.1c2.1,0.1,5.1-2.1,7-3c0.2-0.1,0.4-0.1,0.6-0.1c3.1,0.4,10,1.9,11.4,4.7c1,2,2.2,4,3.5,6c0,0,0,0.1,0.1,0.1
	c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1-0.1l4-3.7c0.2-0.1,0.2-0.3,0.1-0.5c-7.7-12.8-17.8-29.4-24.4-45c-5-11.8-8.6-24.5-12.7-36.7
	c-2.3-6.9-5.6-14.4-14.4-14.2c-6.5,0.2-12.7,4-15.8,9.7c-3.3,6.3-3.8,16.3-3.3,23.5c0.7,10.1,2.8,21,6.4,30.7
	c1.2,3.3,3.4,6.9,5,10.4c0.1,0.2,0.1,0.4-0.2,0.5c-2,1-4.1,1.4-6.3,1.3c-0.2,0-0.5-0.1-0.6-0.3c-1.2-1.1-2.1-2.4-2.9-3.8
	c-11.9-23.7-15.2-55-0.9-78.6c2.5-4.1,5.9-6.3,10.2-6.7c15.3-1.3,27.5,10.2,33.8,23.2c7,14.6,9.2,31.4,15.5,46.6
	c4.2,10.1,9,19.9,14.5,29.3c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l3.5-2.7C773,246.1,773,245.9,772.9,245.7z M705.4,186.1c2-3.3,6.5-3.7,10.3-2.8c5.8,1.4,9.6,5.1,11.3,10.8
	c3.9,13.5,8.1,26.9,15,38.6c3.1,5.2,6.3,10.5,9.7,15.8c0,0.1,0,0.1,0,0.2v0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0h0c-0.1,0-0.1,0-0.2,0c-3.4-2.1-7.1-2.8-11-2c-0.2,0-0.4,0-0.5-0.2c-8.8-15.6-16.3-31.9-19.6-49.6
	c-0.8-4-5.5-5.8-8.4-2.3c-3.6,4.2,8.7,28.9,10.9,32.8c3.7,6.4,8.7,13.2,11.2,20.1c0.1,0.2,0,0.3-0.2,0.2c-5.5-2.6-10.3-6.4-16.6-7.8
	c-0.7-0.2-1.4-0.7-1.8-1.3C708.1,226.1,696.8,200.1,705.4,186.1z"
        />
        <path
          class="st0"
          d="M828.5,145.3C828.5,145.3,828.5,145.2,828.5,145.3c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c-0.6,1.1-0.6,2,0.1,2.9c0,0,0.1,0.1,0.1,0.1
	c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
	c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2l0-2.5C828.6,145.5,828.6,145.4,828.5,145.3C828.6,145.3,828.6,145.3,828.5,145.3z"
        />
        <path
          class="st0"
          d="M771.5,146c-2.4,4.1-3.1,5-0.1,9.2c0.1,0.2,0.4,0.3,0.6,0.1l4.3-2.7c0.1-0.1,0.2-0.2,0.1-0.3L772,146
	C771.8,145.7,771.7,145.7,771.5,146z"
        />
        <path
          class="st0"
          d="M816.5,151.6c-0.3,0.3-0.5,0.6-0.8,0.8c-1,0.9-1.8,1.8-2.7,2.7c-0.5,0.5-0.9,0.9-1.3,1.4c-9.4-1.8-20.1,0.5-27.8,4.7
	c-8.6,4.6-14.5,11.3-16.2,20.9c-2.8,15.7,4,35.2,10.2,49.6c0.1,0.2,0.1,0.5-0.2,0.6l-3.4,2.7c-0.2,0.2-0.4,0.2-0.6-0.1
	c-5.4-11.6-9.2-23.7-11.5-36.2c-1.1-5.6-1.6-9.8-1.6-12.5c0-10.3,5.2-20.2,14.1-25.8C787.1,152.5,802.3,149.2,816.5,151.6z"
        />
        <path
          class="st0"
          d="M788.7,212.4c1-0.4,1.9-0.9,2.8-1.4c0,2,0,4,0,6.2c-1.5,0.3-3,0.1-3.9-1.1C786.3,214.6,787.1,213.2,788.7,212.4z"
        />
        <path
          class="st0"
          d="M807.7,160.9c-1.7,1.8-3.1,3.5-4.5,5.2c-0.7,0.9-1.4,1.7-2,2.4h0c-4.3,0.2-8.6,1.4-11.5,3.3c-5,3.3-8.7,9.4-10.3,15.4
	c-3.3,12.4,0.7,25.7,5.4,37.2c0.1,0.2,0.3,0.3,0.6,0.3c2-0.6,4-1.1,6.1-1.6c0,1.8,0,3.7,0,5.5c-3.5,1-8.3,2.5-10-0.9
	c-9.3-18.8-15.2-50.1,7.3-62.9C794,162,801,160.3,807.7,160.9z"
        />
        <path
          class="st0"
          d="M482.1,178.9c-1.1-2.4-3.4-1.8-5.3-1.1c-6.4,2.6-12.4,5.9-17.9,10c-2.5,1.9-1.4,5.4,1.7,5.3c0.6,0,1.5-0.3,2.7-0.9
	c3.4-1.7,6.8-3.4,10.3-5.2C475.9,185.9,483.5,182.1,482.1,178.9z"
        />
        <path
          class="st0"
          d="M505.3,212.9c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.7,0.5c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0.1,0.5,0.1
	c0.7,0.1,1.4-0.2,1.9-0.6c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5
	c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.5-0.5-0.9-0.6c-0.2-0.1-0.3-0.1-0.5-0.2
	s-0.4-0.1-0.5-0.1c-0.5,0-1.1,0.1-1.5,0.3c-0.6,0.3-1,0.8-1.2,1.5c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5
	C505,212.3,505.1,212.6,505.3,212.9z"
        />
        <path
          class="st0"
          d="M619.1,570.5v10.8c0.7,0.5,1.6,0.9,2.5,1.5C621.4,578.6,620.6,574.5,619.1,570.5z M601,291.1c-5.9-18.8-15-37.2-28.7-51.6
	c-11.6-12.2-25.1-17.5-41.9-18.4c-12.5-0.7-20.6-1.1-24.2-1.4c-14.1-0.9-27.8-3.4-41.4-7.4c-0.2-0.1-0.4,0-0.5,0.2
	c-0.7,1.1-1.3,2.2-2,3.3c0,0.1,0.1,0.2,0.1,0.3c1.1,3.4,1.8,6.6,2,9.7c0.8-1.3,1.5-2.6,2.3-3.9c0.1-0.2,0.3-0.2,0.5-0.2
	c13.3,4,25.6,6.3,36.9,7c14,0.9,28.8,1.1,42.2,5.2c14.8,4.4,24.4,13.3,32,26.5c4.9,8.4,9.2,17.5,12.9,27.3
	c8.4,22.2,6.8,48.7-1.2,70.6c-2.4,6.5-6.8,14.6-9.3,20.6c-2.4,5.6-4.3,11.4-5.9,17.3c-0.1,0.4,0.1,0.8,0.5,1l6.3,2.4
	c0.2,0.1,0.4,0,0.5-0.2c3.2-9.4,6.9-18.6,11.2-27.6c2.6-5.4,4.2-9.3,5.1-11.6c4.7-13.4,6.6-27.9,6.7-42.1
	C605.2,309.1,603.8,300,601,291.1z M408.5,368.7c-0.3-13.2,0.1-27.4,1.2-42.6c0.1-2,0.3-3.9,0.6-5.8c-5.4,19.8-8.3,43.6-7.4,60.6
	c0,0,0,0,0,0c1.4,6.5,3.2,13.1,5.6,19.5c2.5,1.5,5.9,3.4,10,5.6C413.3,394.5,408.8,380.9,408.5,368.7z M588.3,480.2
	c-1.1-20.3-8.8-36.5-22.3-51.7c-9-10.1-19.8-15.6-32.9-19.9c-2.8-0.9-4.9-1.9-7-3.5c-0.3-0.3-0.5-0.7-0.3-1.1
	c1.2-3.1,2.9-6.5,3.9-9.2c10.5-28.5,7.9-60.9-8.5-86.8c-6.3-10-15.1-16.4-26.5-19.2c-27-6.7-43.9,9.7-45.9,35.6
	c-0.5,6.2-0.6,12.1-0.4,17.6c0.1,1.5,0.7,2.3,2.2,2.3c0.2,0,0.4-0.1,0.5-0.3c1.5-1.9,2.7-4.1,4.2-5.7c16.5-16.6,46.8-13.7,64-0.2
	c10.6,8.2,11.3,17.3,10.1,29.8c-0.6,6.5-1.9,13-3.8,19.3c-1.5,4.8-4,10.2-6,15.3c-1.4,3.6,2.5,5.2,5,6.3c12.6,5.7,24,9.7,34.3,20.2
	c1.1,1.1,2,2.1,2.6,3c0.2,0.3,0.1,0.3-0.2,0.2c-13.1-5.1-26.8-9.9-39.3-2.1c-0.3,0.2-0.7,0.2-1,0c-18.2-13.7-40.8-22.1-63.1-26
	c-0.2,0-0.3-0.2-0.4-0.4c-7.3-18.4-5.8-42.2,9.8-56.2c11.5-10.3,28.8-10,40.2-0.5c6.9,5.7,10.8,13.4,10,22.3
	c-0.9,10.4-4.7,20.8-8.2,31c-0.1,0.2,0,0.5,0.2,0.5l5.2,1.9c0.2,0.1,0.4,0,0.5-0.2c4.8-11.6,9.6-26.2,8.5-38.9
	c-2-23.6-28.9-35.3-49.5-28.3c-25.4,8.6-32.6,42.6-24.8,65.2c1,2.9,1.3,5.7,4.9,6.4c17.1,3.6,36.4,9.6,50,20.9
	c0.2,0.2,0.2,0.3,0,0.5l-6,4.3c-0.2,0.1-0.4,0.1-0.6,0c-9.5-8.3-17.1-4.9-25.6,0.3l6.6,3.2c5.8-3,13.1-3.9,14.2,4.8
	c0.1,0.8,0.2,1.5,0.2,2.2l5,2.4c2.6,1.2,5.2,2.5,7.7,3.8l2.6,1.3c3,1.5,6,3,9,4.5l0,0c1.9,0.9,3.8,1.8,5.7,2.8c0.4-3.9,0.6-8,1-12.3
	c0-0.3,0.1-0.3,0.4-0.2c6.1,4.1,10,11.3,12.1,19.2c3.4,1.7,6.7,3.4,10,5c-0.1-0.5-0.1-0.9-0.2-1.3c-2.2-13.1-8.3-24.2-18.3-33.4
	c-0.2-0.2-0.1-0.3,0.1-0.4c12-5.4,26.9-1.2,36.3,6.6c6.3,5.2,10.2,12.6,12.4,20.4c2.6,9.1,2.7,16.7,2.7,25.3c0,0.4,0,0.9,0,1.4v0.2
	c2.6,1.4,5.1,2.8,7.5,4.1c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.7,0.4-1.1C588.8,487.6,588.4,482.4,588.3,480.2z M526.3,339
	C526.3,339,526.3,339.1,526.3,339c0,0.1-0.1,0.1-0.1,0.2l0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1c-9.8-10.6-21.7-17.1-36.7-17.8c-13.5-0.7-25.4,3.1-35.8,11.4c-0.2,0.1-0.2,0.1-0.2-0.1
	c-1.1-18.8,6.4-38.5,28.1-39.8c29.2-1.7,40.7,21.8,45.3,46.1C526.3,338.9,526.3,339,526.3,339z M517.1,442c-0.8,1.3-2,2.4-3.5,3
	c-0.4,0.2-0.9,0.3-1.3,0.4c-0.5,0.1-0.9,0.1-1.3,0.1c-1.2,0-2.3-0.3-3.3-0.9c-1.3-0.7-2.3-1.9-2.9-3.4c-1.4-3.5,0.4-7.4,3.9-8.8
	c0.2-0.1,0.3-0.1,0.5-0.2c0.5-0.1,1-0.2,1.5-0.3c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0c2.4,0.3,4.6,1.9,5.5,4.3
	c0.3,0.7,0.4,1.3,0.4,2c0,0.2,0,0.4,0,0.7C518,440,517.7,441.1,517.1,442z"
        />
        <circle cx="579" cy="224.8" r="2.3" class="st0" />
        <path
          class="st0"
          d="M785.3,243.2C785.3,243.2,785.3,243.1,785.3,243.2c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c1.9-0.3,3.8-0.7,5.7-1c0-2.4,0-4.8,0-7.2c-1.7,0.4-3.3,0.8-5,1.3c-2.9,0.8-5.2,1-7.2,3.5c-3,3.8,3.4,16.4,5.6,20.1
	c1.5,2.6,3.8,4.3,6.4,5.2c0-2.7,0-5.4,0.1-8.1c-0.9-0.2-1.7-1.1-2.2-2.4c-1.5-3.6-2.7-7.3-3.7-11
	C785.3,243.3,785.3,243.3,785.3,243.2C785.3,243.2,785.3,243.2,785.3,243.2z"
        />
        <path
          class="st0"
          d="M585,357.5c1.8-4.7,3.1-8.8,3.8-12c7.2-33.2-6.5-76.5-33.8-97.3c-9.1-7-19.9-10-31.3-10.8c-12.1-0.9-24.1-1.9-36.2-3.2
	c-3.6-0.4-7.2-0.3-7.1,4.2c0.1,1.8,2.7,3,4.2,3.3c9.6,1.9,17.5,3.1,23.6,3.5c13.9,0.9,26.5,2,38,8.4c24.4,13.4,35.4,43.6,35.3,70
	c-0.1,17.1-3.2,33.9-9.5,49.8c-1.7,4.4-10.5,24-8.3,27.5c0.7,1.1,1.7,1.9,3,2.4c14.5,5.5,28.2,12.6,41.1,21.2
	c1.8,1.2,3.4,1.1,4.7-0.2c0.2-0.2,0.2-0.3,0.2-0.6c0-1.3-0.5-2.3-1.5-3c-13.2-8.8-28.8-13.3-42.4-21.3c-0.3-0.2-0.4-0.4-0.3-0.8
	C572.8,385,579.8,371.3,585,357.5z"
        />
        <path
          class="st0"
          d="M701.4,256c-2.1-4.1-5.5-6.7-9.4-9.1c-4.2-2.7-6-4.9-8.2-9.6c-0.4-0.8-1.2-1.3-2.3-1.3c-0.2,0-0.4,0-0.5,0.1
	c-3.9,2.7,3.5,10.6,6,12.5c2.2,1.7,5.7,5,10.5,9.8C699.7,260.6,702.7,258.6,701.4,256z"
        />
        <path
          class="st0"
          d="M684.8,254.7c0.9-2.8-6-7.9-8-9.3c-0.2-0.1-0.3-0.1-0.4,0.1c-1.1,3.8-2.9,5.2-5.4,7.5c-2.2,2-1.9,4.9-1.3,7.7
	c0.4,1.8,2.2,6.4,4.4,6.5c4.2,0.3-0.6-9.1-1.2-10.6c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.1
	c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2-0.1C676.4,254.8,683.4,259.1,684.8,254.7z"
        />
        <path
          class="st0"
          d="M462.3,249.5c-2.1,4.2,2.9,5.5,5.9,5.4c11.1-0.4,23.5-1.6,33.9-0.3c8.3,1,17.1,3.2,26.3,6.7c10.5,4,19.1,11.9,25.1,21.3
	c16.8,26.5,18.4,56.6,12,86.4c-1,4.8-1.6,7.9-1.8,9.2c-0.2,1.8,0.6,2.7,2.4,2.6c0.2,0,0.3-0.1,0.5-0.2c0.8-1.1,1.4-2.1,1.6-2.9
	c8.2-32,10.8-70.7-9.6-98.9c-6.5-9-15.6-19.2-26.4-22.1C524,254.5,467.1,240,462.3,249.5z"
        />
        <path
          class="st0"
          d="M712.2,250.9c-1.2,0.1-2,0.7-2.3,1.8c-0.8,2.8,5.3,8.7,7.4,10.7c1.6,1.5,3.1,1.5,4.5,0.1c0.2-0.2,0.2-0.4,0.2-0.6
	C721.9,259.4,715.6,250.5,712.2,250.9z"
        />
        <path
          class="st0"
          d="M778.9,269c1.6,1.3,3.7-2.2,3.9-3.3c0.5-2.7-4.1-6.9-6-8.4c-0.2-0.1-0.3-0.1-0.5,0L773,261c-0.2,0.2-0.2,0.4-0.1,0.6
	C774.7,264.7,776.7,267.1,778.9,269z"
        />
        <path
          class="st0"
          d="M750.6,262.6c-1.1-1.8-2.5-3.5-3.9-5.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0l-2.3,2.3c-0.2,0.2-0.2,0.4-0.1,0.6
	c1.3,2,3.1,3.4,4.9,4.9C750.2,266.8,751.4,263.9,750.6,262.6z"
        />
        <path
          class="st0"
          d="M418.7,315.3c-2.6,17.7-3.6,35.4-3.2,53.2c0.3,13.7,5.1,27.1,11.6,39.3c0.7,1.2,1.7,2.3,3.1,2.6
	c13.3,2.9,25.9,7.7,37.8,14.4c1.6,0.9,4.6,2.1,6.1,0.6c7.3-7.2-36.3-17.3-39.5-17.9c-0.7-0.1-1.4-0.7-1.6-1.3
	c-6.9-17.3-10.5-35.8-9.4-53.7c0.6-10,1.9-20.5,3.8-31.4c1.1-6.6,1.9-10.8,2.3-12.4c4.9-20.6,19.9-39.6,41.6-42.9
	c23.6-3.6,47.5-1,63.8,18.1c15.6,18.3,22.9,42.3,21.6,66c-0.9,16.5-3.9,33.3-8.8,50.3c-0.1,0.3,0,0.4,0.3,0.5l6.6,0.6
	c0.2,0,0.3-0.1,0.4-0.3c4.7-14.5,6.9-29.7,7.9-44.6c1.1-16.4-1-32.5-6.4-48.3c-6.4-18.8-22.2-39.9-41.9-45.5
	c-15.6-4.5-31.7-5.2-48.1-2.3c-10.1,1.8-18,4-25.2,10.5C428.3,282.8,421.3,297.7,418.7,315.3z"
        />
        <path
          class="st0"
          d="M691.9,265.8c-0.2-0.3-0.4-0.6-0.7-0.8c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0h0c-0.3,0-0.5,0.1-0.7,0.3
	c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.3,0.4-0.4,0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0,0.4
	c0.1,0.7,0.4,1.2,0.9,1.6c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.1-0.1,0.2-0.1
	c0.2-0.2,0.4-0.4,0.6-0.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0.1-1c0-0.1,0-0.3-0.1-0.4C692,266,691.9,265.9,691.9,265.8z"
        />
        <path
          class="st0"
          d="M775.7,272C775.7,272,775.7,272,775.7,272C775.7,272,775.7,272,775.7,272l-5.9-7.2c-0.1-0.1-0.3-0.2-0.5-0.1l-5.7,4.6
	c-0.1,0.1-0.2,0.3-0.1,0.5l5.5,7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
	c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l6.1-4.8c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1C775.7,272.1,775.7,272.1,775.7,272C775.7,272.1,775.7,272,775.7,272z"
        />
        <path
          class="st0"
          d="M777.7,285.1c0.2-0.4,0.8-0.5,1.2-0.3c3.6,2,7.7,4.2,12,5.8c0-3,0.1-6,0.1-9c-1.9-0.9-3.8-1.8-5.6-2.6
	c-3.1-1.4-6-1.9-8.6,0.1c-2.4,1.8-6.3,5.3-8.3,5.1c-2.3-0.2-6.4-6.3-7.7-8.2c-3.7-5.3-6.6-2.6-10.6,0.2c-3.5,2.4-5.6,2.6-9.8,2.5
	c-4.3-0.2-6.6-3.1-9-6.1c-0.2-0.2-0.5-0.3-0.7-0.2c-2,0.8-4.2,2-6.3,2.3c-7.1,1-11.4-3.7-15.6-8c-5.5-5.5-8.9,2.1-11,5.7
	c-2.5,4.1-7.9,5.3-11.5,1.8c-1-1-2-1.9-2.9-2.9c-1.9-1.9-4.5,0.8-3.7,2.6c1.7,3.9,6.1,7.6,10.5,8c6.7,0.6,11.6-5.6,14.8-10.8
	c0.2-0.2,0.3-0.2,0.4,0c3.8,6.4,9.5,10.4,17,10.5c1.9,0,4.1-0.5,6.1-0.8c0.2,0,0.4,0,0.6,0.1c4.6,3,7.8,6.6,9.6,10.9
	c2,4.8,0.4,11.4-5,13.1c-6.5,2-15.1-4.4-8.7-10.7c1.6-1.6,7.2-0.8,6.2-4.7c-0.3-1.3-1-2-2-2.1c-4.4-0.6-9.7,2.1-11.8,6
	c-3.2,5.8,0.7,13.3,6.3,16.1c13.7,6.7,27.3-6.6,20.3-20.2c-0.8-1.5-1.7-3.1-2.5-4.6c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1-0.1s0,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1c3.8-1.8,6.6,0,8.4,3.5
	c3.1,6,2.8,12.2-0.7,18.5c-1,1.7-2.8,4.1-2.6,6.1c0.3,3,3.9,5.8,6.4,7.1c7.5,3.9,16.8,1.8,24.2-1.2c0.2-0.1,0.4,0,0.5,0.1
	c3.1,2.5,7.9,3.7,11.8,3.9c0.2,0,0.5,0,0.7,0l0-7.4c-3.2-0.2-6.3-1.2-9-3.3c-5.5-4.1-6.6-15-5.6-21.2
	C776.1,287.8,776.9,286.5,777.7,285.1z M771.2,309.9c-3.8,4.4-11.7,3.4-16.2,0.8c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
	c6.9-9.7,4.9-18.3-1.3-27.5c-0.1-0.2-0.1-0.4,0-0.6c0.6-0.9,1.4-1.5,2.5-1.8c0.4-0.1,0.8,0,1,0.4c2.8,4,5.4,9.7,10.6,10.7
	c0.3,0,0.5,0.3,0.5,0.6c0,5.7,1,11.1,3,16.4c0.3,0.8,0.5,1.7,0.6,2.5C771.4,309.5,771.3,309.7,771.2,309.9z"
        />
        <path
          class="st0"
          d="M663.9,278.4c2.5,2,8.5-0.8,10.4-2.2c0.2-0.1,0.3-0.3,0.3-0.5c0.4-1.6,0-2.8-1.1-3.5c-1.3-1-4.5-2.5-9.6-4.6
	c-0.2-0.1-0.4-0.1-0.6,0.1l-4.5,4.2c-0.2,0.2-0.2,0.4-0.1,0.6C660.1,274.7,661.8,276.7,663.9,278.4z"
        />
        <path
          class="st0"
          d="M642,417.4c-3.7,2-4.4,4.8-6,8c-0.1,0.2-0.3,0.3-0.5,0.3c-4.1,0.2-7.2,0.8-11.2,1.6c-0.3,0-0.4-0.1-0.4-0.3
	c0.6-4.9-4-4.8-6.2-1.6c-3.7,5.3-8.3,7.8-13.5,11.4c-2.6,1.8-9,4.8-10.7,0.3c-0.3-0.9-0.6-2.2-1-3.8c-0.8-3.9-6.5-6.4-9.5-8.7
	c-12-9.2-24.8-16.6-38.5-22.2c-0.5-0.2-0.7-0.7-0.6-1.2c2.9-11.1,6.1-22.2,6.5-33.8c0.1-3.7,0-7.9-0.4-12.6
	c-1.3-14.7-3.7-29.5-8.7-43.4c-7.2-19.9-23.5-36-44.8-39.3c-14.7-2.3-30.6-0.3-42.4,9.6c-12.8,10.7-17.1,25.9-19,41.9
	c-0.9,8.1-1.6,15.4-2.1,22.1c-1.3,18.8,0.6,37.3,5.8,55.5c0.8,2.9,4.5,4.5,6.1,1.1c0.1-0.2,0.1-0.4,0.1-0.6
	c-0.1-0.8-0.2-1.3-0.3-1.5c-3.5-12-5.2-24.2-5.1-36.7c0.1-13.4,0.9-27,2.7-40.3c1.8-13.5,5.5-26.1,16-35.4c6.3-5.6,12.8-8,21.1-8.4
	c17.2-0.7,35.1,4.7,45.5,19.1c14.7,20.5,22.6,48,18.8,73.2c-1.5,9.9-4.2,20.1-6.8,30.7c-0.1,0.2,0,0.4,0.2,0.5
	c9.4,3.8,18.2,7.7,26.6,13.5c7.1,4.9,14,10,20.8,15.3c5.6,4.4,1.5,12.4,11.1,13.1c6.3,0.5,13.7-5.3,18.7-8.8c0,0,0.1,0,0.1,0
	c0,0,0,0,0,0h0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.2c-1.2,19.7,18.5,15.8,24.1,2.9
	c0.2-0.5,1-3,2.4-7.6c0.7-2.3,2.4-12.4,6.1-11c0.2,0.1,0.4,0.2,0.5,0.4l1.1,1.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0.1,0h0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1C651.6,417.3,645.8,415.4,642,417.4z
	 M626,443c-4.1-0.7-5.8-6.5-5.7-10c0-0.2,0.1-0.3,0.3-0.3c3.3-0.1,6.4-0.8,9.4-2c0.8-0.3,4.7-1.4,4.8,0.3
	C635.1,435,630.9,443.8,626,443z"
        />
        <path
          class="st0"
          d="M660.4,284.3c-1.9-3-6.5-6.1-9-7.7c-0.2-0.1-0.4-0.1-0.6,0c-1.5,1.1-2.6,2.6-3.2,4.3c-0.1,0.2-0.1,0.5,0,0.7
	c1.6,3.9,2,7.9,1.2,12c-0.1,0.4,0,0.5,0.3,0.2c3.3-3.5,7-6.4,11.1-8.9C660.5,284.7,660.5,284.6,660.4,284.3z"
        />
        <path
          class="st0"
          d="M675.8,282.6c-1.2,2.4-1,5.5-4,7.2c-3.7,2.1-10.1,5.2-13,9.3c-4.1,5.9-2.1,16.2,1,22.1c1.4,2.6,5.4,1.7,3.9-1.4
	c-4-8.4-5.5-18.7,5.1-22.9c6.6-2.6,12.1-5,11-13C679.6,281.9,677.2,279.9,675.8,282.6z"
        />
        <path
          class="st0"
          d="M693.6,312.5c1.3,2.3,3.1,4.4,5.3,6.4c2,1.7,6.5,1.7,4.1-1.9c-2.1-3.2-4.4-5.1-3.1-9.6c1.2-4.3,3.8-7.1,7.9-8.7
	c0.2-0.1,0.3-0.2,0.5-0.3c2.5-3,4.2-6.5,6.9-9.5c3.2-3.4-1.5-7.6-4.9-2.8c-2.8,4.1-6.2,7.8-9.9,11.1
	C696.7,300.4,690.7,307.2,693.6,312.5z"
        />
        <path
          class="st0"
          d="M692.4,288.6c-8.2,5.8-17.7,21-6.8,29.3c1.8,1.4,5.6,2.9,5.7-0.7c0-0.2-0.1-0.4-0.2-0.5c-1.4-1.7-3.6-3.5-4.5-5.5
	c-2.2-4.4,1.7-11.8,4.7-15c1.4-1.6,3.6-2.4,5.4-3.5C700.8,290.2,695.7,286.3,692.4,288.6z"
        />
        <path
          class="st0"
          d="M640.5,294.7c0.2,0,0.4,0,0.6,0c0.6-0.1,1.1-0.4,1.5-0.8c0.4-0.4,0.7-0.9,0.9-1.5c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4,0-0.6
	c0-0.2-0.1-0.3-0.1-0.5s-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.2-0.4-0.4-0.7-0.5
	c-0.3-0.2-0.7-0.3-1.1-0.3c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c-0.4,0.1-0.8,0.2-1.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.8,0.7-1.2,1.8-1,2.9C638,293.5,639.2,294.6,640.5,294.7z"
        />
        <path
          class="st0"
          d="M628.9,297.3c0.4,0.2,0.8,0.2,1.2,0.2c1.7,0,3-1.3,3-3c0-0.2,0-0.4-0.1-0.6c-0.1-0.4-0.2-0.8-0.5-1.1
	c-0.5-0.8-1.5-1.3-2.5-1.3c0,0,0,0,0,0c-0.4,0-0.8,0.1-1.2,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.3,0.3-0.6,0.7-0.8,1.1c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
	C627.6,296.4,628.1,297,628.9,297.3z"
        />
        <path
          class="st0"
          d="M794.9,300c-0.3-0.4-0.6-0.8-0.9-1.2c-0.9-0.9-2.1-1.4-3.4-1.6c-0.2,0-0.4,0-0.6,0c-3.2,0-5.8,2.6-5.8,5.8
	c0,3.2,2.6,5.8,5.8,5.8c0.2,0,0.4,0,0.6,0c1.2-0.1,2.3-0.6,3.2-1.3c0.5-0.4,0.9-0.8,1.2-1.3c0.6-0.9,0.9-2,0.9-3.2
	C795.7,301.9,795.4,300.9,794.9,300z M790.8,302.9c0,0.2,0,0.4-0.1,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0
	c-0.1,0-0.2,0.1-0.4,0c-0.2,0-0.5-0.1-0.7-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1-0.1-0.3-0.1-0.4v0c0-0.3,0.1-0.6,0.3-0.8
	c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6,0c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.2
	c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.2C790.8,302.8,790.8,302.8,790.8,302.9L790.8,302.9z"
        />
        <path
          class="st0"
          d="M487.8,299.9c-0.4-0.2-0.8-0.2-1.2-0.2c-1.7,0-3,1.4-3,3c0,1.7,1.4,3,3,3c1.7,0,3-1.4,3-3c0-0.8-0.3-1.6-0.9-2.2
	C488.5,300.3,488.2,300.1,487.8,299.9z"
        />
        <path
          class="st0"
          d="M625.5,307.2c0,0,0.1,0,0.1,0c8.4-0.7,16.7-1.5,25-2.5c0.2,0,0.3-0.1,0.3-0.4v-3.8c0-0.6-0.3-0.8-0.9-0.7
	c-8.4,1.8-16.5,3.5-24.2,4.9c-0.6,0.1-0.9,0.5-0.8,1.1c0,0.2,0,0.3-0.1,0.5C624.8,306.8,625,307.1,625.5,307.2z"
        />
        <path
          class="st0"
          d="M670.8,301.1c-3.4,3.8-4.3,8-2.6,12.7c1.2,3.3,5.2,6.6,8.7,7.1c0.6,0.1,1.2-0.2,1.4-0.8c0.3-0.8,0.2-1.5-0.5-2.1
	c-6.7-5.4-6.9-7.4-3.6-15.3C675.2,300.4,672.2,299.6,670.8,301.1z"
        />
        <path
          class="st0"
          d="M712.3,314.8c-5.8-2.8,3-10.5-0.7-11.6c-1.6-0.5-2.9,0.7-4,1.9c-6.2,6.6,1.1,16.5,8.6,16.8c1.6,0.1,2.3-0.7,2.3-2.4
	c0-0.2-0.1-0.4-0.2-0.6C716.9,317.4,714.9,316,712.3,314.8z"
        />
        <path
          class="st0"
          d="M477,306.9c-0.2-0.3-0.4-0.6-0.7-0.8c-0.2-0.1-0.3-0.2-0.5-0.3c-0.4-0.2-0.7-0.2-1.1-0.2c-0.2,0-0.5,0-0.7,0.1
	c-0.4,0.1-0.8,0.3-1.2,0.6c-0.4,0.3-0.6,0.7-0.9,1.1v0c-0.4,0.8-0.4,1.6-0.1,2.3c0.1,0.4,0.3,0.7,0.6,1c0.2,0.3,0.6,0.5,0.9,0.7
	c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0.1,0.6,0.1c0.7,0,1.4-0.3,2-0.8c0,0,0.1,0,0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.5s0.2-0.4,0.3-0.5
	c0.3-0.6,0.3-1.2,0.3-1.8C477.4,307.6,477.2,307.3,477,306.9z"
        />
        <path
          class="st0"
          d="M503.5,308.6c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.1c-1.6,0-3,1.3-3,3c0,0.2,0,0.4,0.1,0.6c0.1,0.5,0.3,1,0.7,1.3
	c0.5,0.6,1.4,1,2.2,1c0,0,0,0,0,0c0.2,0,0.4,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.9-0.5,1.5-1.5,1.5-2.6
	C505.4,310.2,504.6,309.1,503.5,308.6z"
        />
        <path
          class="st0"
          d="M467.1,315.5c-0.2-0.3-0.4-0.7-0.6-0.9c-0.3-0.3-0.6-0.5-0.9-0.6c-0.2-0.1-0.4-0.1-0.5-0.1h0c-1.4-0.2-2.7,0.7-3.1,2.1
	c0,0.1-0.1,0.3-0.1,0.4c-0.2,1.1,0.3,2.2,1.1,2.8c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.4,0.1,0.5,0.1h0c0.6,0.1,1.1,0,1.6-0.3
	c0.6-0.3,1.2-0.9,1.4-1.7c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.5,0-0.9-0.1-1.3C467.2,315.7,467.1,315.6,467.1,315.5z"
        />
        <path
          class="st0"
          d="M735.4,318.3c-2.8-0.2-5.3,0.6-7.6,2.4c-0.2,0.1-0.3,0.3-0.3,0.5c-0.8,4,8.1,4.6,10.1,1.1
	C738.7,320.5,737.6,318.5,735.4,318.3z"
        />
        <path
          class="st0"
          d="M746.2,321.7c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.2c-0.3,3.2,0.4,7.3,3.2,9.2c2.8,1.9,5.4-1.2,6.2-3.7
	c0.1-0.2,0-0.4,0-0.6C753.4,323.4,748.4,326.2,746.2,321.7z"
        />
        <path
          class="st0"
          d="M774.3,334.3c-0.4-4.6-4.6-7.9-9.3-7.5s-8.3,4.5-7.9,9c0.4,4.5,4.6,7.9,9.3,7.5C771.2,342.9,774.7,338.8,774.3,334.3z
	 M769.5,337.8c-0.2,0.2-0.3,0.4-0.5,0.6c-0.6,0.6-1.3,1-2.2,1.2c-0.3,0.1-0.7,0.1-1,0.1c-0.5,0-0.9,0-1.4-0.1
	c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.5-1-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.4-0.6-0.9-0.7-1.5c-0.1-0.3-0.1-0.5-0.1-0.8
	c0-0.7,0.1-1.4,0.5-2c0.1-0.2,0.3-0.5,0.5-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3
	c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.1,0.9-0.2,1.3-0.2c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.1
	c0.3,0.1,0.6,0.2,0.8,0.3c0.3,0.1,0.5,0.3,0.7,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.1,0.2,0.2,0.4
	c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.2,0.8,0.2,1.2C770.2,336.3,770,337.1,769.5,337.8z"
        />
        <path
          class="st0"
          d="M794.2,333c-0.2-0.6-0.4-1.2-0.8-1.8c-0.7-1.3-1.8-2.3-3.1-3c-1.8-1-4-1.3-6.2-0.7c-4.4,1.2-7,5.7-5.8,9.9
	c0,0.2,0.1,0.3,0.2,0.5c0.1,0.3,0.3,0.7,0.4,1c0.4,0.8,1,1.6,1.7,2.2c0.2,0.2,0.4,0.3,0.5,0.5c0.3,0.2,0.6,0.4,0.8,0.5
	c0.1,0,0.1,0.1,0.1,0.1c0.6,0.3,1.2,0.5,1.8,0.7c0.6,0.2,1.3,0.3,1.9,0.3h0.1c0.3,0,0.7,0,1,0c0.5,0,1-0.1,1.4-0.3
	c0.7-0.2,1.3-0.4,1.9-0.8c1.3-0.8,2.4-1.8,3.1-3.1c0.7-1.2,1.1-2.6,1.1-4C794.5,334.4,794.4,333.7,794.2,333z M790.3,338.2
	c-0.1,0.2-0.3,0.4-0.4,0.6c-0.5,0.6-1.2,1.1-1.9,1.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1,0.1c-2.8,0-5.1-2.3-5.1-5.1
	c0-2.8,2.3-5.1,5.1-5.1c1.8,0,3.5,1,4.3,2.5c0.5,0.8,0.7,1.6,0.7,2.6C791.1,336.5,790.8,337.4,790.3,338.2z"
        />
        <path
          class="st0"
          d="M667.3,341.1c-1.3,10.8,5.9,19.5,16.5,20.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1s0-0.1-0.1-0.1c-3.5-4.4-6.5-8.4-7.7-14c-1.6-7.3,0.5-13,6.3-17.2
	c0.6-0.4,1-0.8,1.3-1.2c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
	c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0
	c-0.1,0-0.1,0-0.2,0C674.5,326.8,668.3,332.7,667.3,341.1z"
        />
        <path
          class="st0"
          d="M663.2,329.6c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-6.6-1.2-13,4.1-12.1,10.9c0.8,6.7,7.2,12.8,13.9,13.5
	c0.4,0,0.4-0.1,0.3-0.4c-3.1-5-6.2-10.1-4.8-16.6C660.5,333.9,661.9,331.6,663.2,329.6z"
        />
        <path
          class="st0"
          d="M699.2,333.4c1-2.4-0.3-4.3-2.9-4.1c-2.1,0.1-3.9,0.9-5.3,2.3c-6.1,6.2-7.9,13.7-5.3,22.6c2.3,7.8,8.7,14.8,17.4,15
	c0.3,0,0.4-0.1,0.3-0.4c-0.2-0.8-0.5-1.4-0.9-2c-5.1-7-9.1-15.7-6.8-24.5C696.4,339.4,697.9,336.3,699.2,333.4z"
        />
        <path
          class="st0"
          d="M712.5,330.7c-10.8,2.7-13.8,12.5-10.8,22.3c3.1,10,11.2,19.6,22.7,18.2c0.3,0,0.3-0.1,0.1-0.3c-2.3-2-4.5-4.1-6.5-6.4
	c-8.1-8.8-6.7-19.1-1.8-29.2C717.8,332.2,715.7,329.9,712.5,330.7z"
        />
        <path
          class="st0"
          d="M725.9,333.8c0.5-1.3,0.4-2.6-1-3.1c-1.6-0.5-3.1,1.2-3.8,2.4c-5.4,9.1-4.3,19.8,2.3,27.9c2.1,2.6,3.9,4.3,5.5,5.4
	c3.7,2.4,9.2,3,12.5-0.7c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-2.8-1.1-6-2.1-8.3-3.5c-7.1-4.4-11.2-13.5-9.1-21.7C724,339,724.7,337,725.9,333.8z"
        />
        <path
          class="st0"
          d="M645.1,331.1c-0.7-0.3-1.4-0.3-2.1,0c-0.2,0.1-0.3,0.2-0.3,0.5c0,0.9,0.4,1.6,1.4,2c0.6,0.2,1,0,1.1-0.6l0.3-1.3
	C645.4,331.4,645.3,331.2,645.1,331.1z"
        />
        <path
          class="st0"
          d="M635.1,332.7c-0.4-0.7-1.1-1.2-1.8-1.3c0,0,0,0,0,0c-0.2,0-0.3,0-0.5,0c0,0,0,0,0,0c-0.3,0-0.6,0.1-0.9,0.3
	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.6,0.7-0.7,1.1c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5
	c0.1,0.6,0.4,1.2,0.8,1.6c0.1,0.1,0.3,0.2,0.5,0.3c0.4,0.2,0.9,0.3,1.3,0.3c0.3,0,0.6-0.1,0.9-0.3c0.4-0.2,0.7-0.6,0.9-1
	c0.1-0.3,0.2-0.6,0.2-0.9c0-0.2,0-0.3,0-0.5C635.4,333.3,635.2,332.9,635.1,332.7z"
        />
        <path
          class="st0"
          d="M752.3,341.2c-0.2-0.4-0.4-0.8-0.7-1.1c-0.5-0.7-1.1-1.4-1.8-1.9c-0.2-0.2-0.5-0.4-0.7-0.5c-0.5-0.3-1.1-0.6-1.7-0.9
	c-0.3-0.1-0.6-0.2-0.9-0.3c-5-1.6-10.2,0.9-11.6,5.4c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.3,0.1,0.7,0.1,1
	c0.1,0.4,0.2,0.8,0.3,1.3c0.2,0.5,0.4,1,0.6,1.4c0.5,0.9,1.1,1.7,1.8,2.4c0,0,0.1,0.1,0.1,0.1c0.5,0.4,1,0.8,1.6,1.2
	c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.3,1.1,0.5,1.8,0.7c5,1.6,10.2-0.9,11.6-5.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.6,0.2-1.3,0.2-1.9
	C753.2,343.6,752.9,342.3,752.3,341.2z M749.2,345.9c-0.2,0.7-0.6,1.3-1.1,1.8c-0.2,0.3-0.5,0.5-0.8,0.7c-0.1,0.1-0.3,0.2-0.4,0.3
	c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.3-1.3,0.4-2,0.5c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.6,0c-0.6,0-1.2-0.1-1.7-0.3c-3.1-1-4.9-4-4-6.8
	c0.9-2.8,4.1-4.2,7.2-3.2c0.7,0.2,1.3,0.5,1.8,0.9c0.5,0.4,1,0.8,1.3,1.3c0.1,0.1,0.1,0.2,0.2,0.2c0.4,0.5,0.6,1.1,0.8,1.8
	c0.1,0.3,0.1,0.7,0.1,1C749.5,344.9,749.4,345.4,749.2,345.9z"
        />
        <path
          class="st0"
          d="M641.2,340c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3
	c0.3,0.2,0.7,0.4,1.2,0.4c0,0,0,0,0,0c1.1,0,2.1-1,2.1-2.2c0,0,0,0,0,0c0-1.2-0.9-2.2-2.1-2.2c0,0,0,0,0,0c-0.6,0-1.1,0.3-1.5,0.7
	C641.4,339.2,641.2,339.6,641.2,340z"
        />
        <path
          class="st0"
          d="M633.9,343.4c0,0.5,0.1,0.9,0.4,1.3c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.5,1,0.8,1.7,0.8c0,0,0,0,0,0c0.6,0,1.2-0.3,1.6-0.7
	c0.4-0.4,0.7-1,0.7-1.6c0-1.2-1-2.3-2.3-2.3S633.9,342.1,633.9,343.4z"
        />
        <path
          class="st0"
          d="M488.8,350.7c11.8,1.2,18.9,9.2,17.1,21.2c-1.2,8.1-4.5,16.9-7,25.2c-0.5,1.8-0.6,3-0.3,3.8c1.4,2.9,5,3,6.3,0
	c5.3-13.1,12.1-30.1,5.5-44c-5.4-11.6-17.9-15.5-29.8-12.8c-18.2,4-25.3,23.1-21.9,40.1c1.2,6.4,2.7,12.1,4.5,17.3
	c0.1,0.3,0.3,0.4,0.5,0.4h5.5c0.3,0,0.4-0.1,0.3-0.4c-5.1-12-8.8-28.1-2.1-39.8C472.1,353.7,479.5,349.8,488.8,350.7z"
        />
        <path
          class="st0"
          d="M790.5,350c-2.9,0.4-4.6,6.2-5.5,9.2c-1.2,4.4-3.9,7.9-8.1,10.5c-0.8,0.5-1.4,1.3-1.9,2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0
	c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c2.2,0.3,3.7-0.3,5.8-1.3c8.1-4,9.3-11.8,10.8-19.8
	C792.8,350.8,792,349.8,790.5,350z"
        />
        <path
          class="st0"
          d="M778.8,346.5c1.8,2.4,3.3,5,4.4,7.7c0.1,0.3,0.2,0.3,0.4,0.1c1.4-2,2.4-4.2,3-6.6c0.1-0.3-0.1-0.7-0.4-0.8
	c-2-0.7-4.9-1-7.1-1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0C778.8,346.5,778.8,346.5,778.8,346.5z"
        />
        <path
          class="st0"
          d="M772.6,347.6c-0.6-0.2-1.1-0.3-1.7-0.3c-0.8-0.1-1.7-0.1-2.5,0.1c-1.3,0.2-2.6,0.8-3.7,1.6c-0.6,0.5-1.2,1.1-1.7,1.8
	c-0.2,0.2-0.3,0.5-0.4,0.7c-0.3,0.5-0.5,1-0.7,1.6c-1.4,4.4,1.1,9.1,5.6,10.6c0.6,0.2,1.1,0.3,1.7,0.4c0.6,0.1,1.1,0.1,1.7,0
	c1.1-0.1,2.2-0.4,3.1-0.9c0.5-0.2,0.9-0.5,1.4-0.9c0.4-0.3,0.8-0.7,1.2-1.1c0.4-0.4,0.7-0.9,0.9-1.3c0.3-0.5,0.5-1,0.7-1.5
	C779.6,353.7,777.1,349,772.6,347.6z M770.2,361.2c-3.1,0-5.6-2.5-5.6-5.6c0-0.4,0-0.8,0.1-1.1c0.2-0.9,0.6-1.7,1.2-2.4
	c0.2-0.3,0.5-0.5,0.8-0.8c0.4-0.3,0.9-0.6,1.4-0.8c0.5-0.2,1-0.4,1.6-0.4c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0
	c0.6,0.1,1.1,0.2,1.6,0.4c0.5,0.2,1,0.5,1.4,0.8c0.3,0.2,0.5,0.5,0.8,0.8c0.6,0.7,1,1.5,1.2,2.4c0.1,0.4,0.1,0.7,0.1,1.1
	C775.7,358.7,773.2,361.2,770.2,361.2z"
        />
        <path
          class="st0"
          d="M616.7,349.4l-3,5.7c-0.1,0.3-0.1,0.5,0.2,0.6c20.8,12.5,46.3,19.8,70.2,22.5c0.2,0,0.4-0.1,0.5-0.3l1.9-3.7
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-23.8-4.5-48.1-12-69-24.4C616.9,349.1,616.8,349.2,616.7,349.4z"
        />
        <path
          class="st0"
          d="M813.2,453.6c-17,18.8-43.8,21.2-64.6,27.9c-8.9-0.1-17.9-1.2-26.7-2.9c-0.3-0.1-0.5,0.1-0.4,0.4
	c1.4,11.9,3.2,23.8,5.3,35.6c0,0.2,0,0.3-0.3,0.4l-6.6,1.8c-0.6,0.1-0.9-0.1-1-0.6l-6-45.4c0-0.3,0.1-0.4,0.3-0.3
	c16.6,2.4,32.9,4,49.7,2.9c13.5-0.9,28.2-4.5,38.9-13.3c3.1-2.5,6-5.4,8.6-8.6C811.3,452.2,812.2,452.9,813.2,453.6z"
        />
        <path
          class="st0"
          d="M754.3,355.3C754.2,355.2,754.2,355.3,754.3,355.3c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1l-4.8,8
	c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0l9.3-1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1L754.3,355.3C754.3,355.3,754.3,355.3,754.3,355.3z"
        />
        <path
          class="st0"
          d="M495.7,361.9c-3.5-6.3-11.4-5.8-16.7-2.4c-12.7,8.2-7.3,29.1-0.5,39c5.5,8.1,13.2,2.4,16.3-3.9
	C499.8,384.3,501.4,372.2,495.7,361.9z M486,395.6c-0.2,0.1-0.5,0.1-0.7-0.1c-5.7-5.9-6-16.1-4.8-23.6c0.6-3.9,2.8-5.7,6.6-5.4
	C499,367.5,492.8,391.4,486,395.6z"
        />
        <path
          class="st0"
          d="M605.6,360.1c-7.8,11.7-13.6,24.4-17.5,37.9c-0.2,0.6,0.1,1.1,0.8,1.5c9.8,6.1,19.3,11.2,28.5,15.3
	c2.6,1.2,14.2,5.6,15.9,3.4c1.8-2.4-1.6-3.8-3.2-4.5c-11.8-5.1-23.9-10.7-34.7-17.7c-0.4-0.2-0.5-0.7-0.4-1.1l2.3-6.2
	c0.1-0.2,0.2-0.3,0.4-0.1l9.9,6.4c0.2,0.1,0.4,0.1,0.5-0.1l2.3-4.3c0.1-0.2,0.1-0.4-0.1-0.5l-10.8-6.4c-0.3-0.1-0.3-0.4-0.2-0.6
	l2.2-4.7c0.1-0.3,0.4-0.4,0.7-0.3l5.2,2.3c0.3,0.1,0.7,0,0.8-0.3l1.5-3.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0l-5-2.2
	c-0.3-0.1-0.3-0.3-0.2-0.5l3-5.4c0.1-0.2,0.3-0.3,0.5-0.2c4.3,2.5,8.8,4.7,13.4,6.7c0.3,0.1,0.5,0,0.6-0.2l1.6-3.3
	c0.1-0.2,0.1-0.4-0.2-0.5c-6.7-2.8-12.3-5.8-17.2-10.6C605.9,359.9,605.8,360,605.6,360.1z"
        />
        <path
          class="st0"
          d="M780.5,372.5c-0.4-0.5-0.9-1-1.4-1.5c-0.5-0.5-1.1-0.8-1.7-1.1c-0.2-0.1-0.3-0.1-0.5-0.2c0,0-0.1-0.1-0.2-0.1
	c-0.3-0.1-0.6-0.2-0.8-0.3c-0.9-0.2-1.7-0.4-2.6-0.4c-0.9,0-1.8,0.1-2.6,0.4c-0.3,0.1-0.6,0.2-0.8,0.3c-0.4,0.2-0.7,0.3-1,0.5
	c-1,0.6-1.9,1.3-2.7,2.2c-3.3,4-2.9,9.8,1,13c3.9,3.2,9.7,2.6,13-1.4C782.9,380.7,783,375.9,780.5,372.5z M773.1,383.8
	c-3,0-5.5-2.4-5.5-5.4c0-3,2.4-5.5,5.5-5.5s5.5,2.4,5.5,5.5C778.6,381.4,776.1,383.8,773.1,383.8z"
        />
        <path
          class="st0"
          d="M746.2,381c5,3,6.5-3.2,7.1-6.5c0.1-0.8-0.1-1.6-0.7-2.2C747.9,366.9,741.6,378.2,746.2,381z"
        />
        <path
          class="st0"
          d="M632.1,379c0.3,0.1,0.5,0,0.5-0.3c0.2-1.2,0.7-2.4,1.5-3.4c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-8-2.9c-0.2-0.1-0.3,0-0.4,0.1l-1.9,3.6
	c-0.1,0.2,0,0.3,0.1,0.3L632.1,379z"
        />
        <path
          class="st0"
          d="M673.4,417.9C673.4,418,673.4,418,673.4,417.9c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l4.8,1.4
	c0.2,0.1,0.4,0,0.5-0.2c0.5-1,1.2-2,1.6-3.1c5.5-13.8,12-27.2,19.5-40.1c0.1-0.2,0-0.4-0.1-0.5l-5.7-3.4c-0.2-0.1-0.3-0.1-0.5,0.1
	c-9.7,13.5-15.3,29.8-20.2,45.4c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1C673.4,417.8,673.4,417.9,673.4,417.9
	C673.4,417.9,673.4,417.9,673.4,417.9z"
        />
        <path
          class="st0"
          d="M634.5,379.8C634.5,379.8,634.5,379.8,634.5,379.8C634.5,379.8,634.5,379.8,634.5,379.8
	C634.5,379.8,634.6,379.9,634.5,379.8c0.1,0.1,0.1,0.1,0.1,0.1l7.2,2.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l1.5-4.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-6.9-2c-0.1,0-0.2,0-0.3,0.1l-1.8,3.9
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C634.4,379.7,634.4,379.7,634.5,379.8C634.4,379.7,634.4,379.7,634.5,379.8
	C634.5,379.8,634.5,379.8,634.5,379.8z"
        />
        <path
          class="st0"
          d="M726.9,377.4c-1.5,0.8-2.9,2-4.1,3.5c-6.7,8.7-12.3,18-16.9,27.9c-0.6,1.2-1,2.5-1.4,3.8c-0.8,2.5,3.4,3,4.9,3
	c0.4,0,0.7-0.2,0.9-0.6c3.9-10.1,7.4-18.9,14.3-28.3c1.5-2.1,3.1-4.3,4.5-6.5C730.2,378.4,728.4,376.5,726.9,377.4z"
        />
        <path
          class="st0"
          d="M620.3,380.9l-8.4-3.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0l-1.5,3.7c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.2l8.4,3.5c0.2,0.1,0.3,0,0.4-0.2c0,0,0,0,0,0l1.5-3.7c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	C620.4,381,620.4,380.9,620.3,380.9z"
        />
        <path
          class="st0"
          d="M687.8,422c3.2,2.4,5.4-0.1,6.4-2.9c5.1-15,9.2-26.6,16.7-39.2c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.1,0-0.2-0.1-0.2-0.1l-3.2-0.7c-0.2-0.1-0.4,0-0.6,0.2c-10.1,12.2-15.4,25-19.9,40.3C686,419.8,686.5,421.1,687.8,422z"
        />
        <path
          class="st0"
          d="M644,383C644,383,644,383,644,383C644,383,644.1,383,644,383C644.1,383,644.1,383,644,383c0.1,0.1,0.1,0.1,0.1,0.1l8.5,2.4
	c0.1,0,0.2,0,0.3-0.1l1.9-4.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0l-8.7-2.1c-0.1,0-0.2,0-0.3,0.1l-1.6,4.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C644,382.9,644,382.9,644,383
	C644,382.9,644,382.9,644,383C644,382.9,644,383,644,383z"
        />
        <path
          class="st0"
          d="M737.3,380.9c-0.2-0.1-0.4,0-0.5,0.1c-1.1,1-3,3.3-1.2,4.3C740.1,387.9,740.9,381.9,737.3,380.9z"
        />
        <path
          class="st0"
          d="M655,386.1C655,386.1,655,386.1,655,386.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0l7.6,1.8
	c0.2,0,0.4-0.1,0.5-0.3l1.7-4.5c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0l-7.5-1.5c-0.2,0-0.4,0.1-0.5,0.3l-1.7,4.2c0,0,0,0,0,0.1c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C654.9,386,654.9,386,655,386.1C654.9,386,654.9,386.1,655,386.1z"
        />
        <path
          class="st0"
          d="M622.2,382l-1.9,3.7c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l25.5,9.9c0.3,0.1,0.7,0,0.9-0.4l2-4.7c0.1-0.2,0-0.3-0.2-0.4
	c-8.8-2.6-17.5-5.6-26.1-8.9C622.4,381.8,622.3,381.8,622.2,382z"
        />
        <path
          class="st0"
          d="M759,383.2c-0.4-0.2-0.8-0.3-1.3-0.3c-0.9,0-1.8,0.4-2.4,1c-0.2,0.2-0.3,0.3-0.4,0.5c-0.4,0.5-0.6,1.2-0.6,1.9c0,0,0,0,0,0
	c0,0.5,0.1,0.9,0.3,1.3c0.2,0.5,0.5,0.9,0.8,1.2c0.6,0.5,1.4,0.9,2.3,0.9h0c0.6,0,1.2-0.2,1.7-0.5c0.2-0.1,0.5-0.3,0.7-0.5
	c0.6-0.6,1-1.4,1-2.4C761.1,384.9,760.2,383.7,759,383.2z"
        />
        <path
          class="st0"
          d="M665.1,388.5C665.1,388.5,665.1,388.5,665.1,388.5C665.1,388.5,665.1,388.5,665.1,388.5l13.1,2.4c0.1,0,0.2,0,0.2-0.1
	l2.4-5.4c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0l-13.4-1.9c-0.1,0-0.2,0-0.2,0.1
	l-2,4.9c0,0,0,0,0,0c0,0,0,0,0,0.1C665,388.4,665,388.4,665.1,388.5C665,388.4,665,388.4,665.1,388.5z"
        />
        <path
          class="st0"
          d="M794.1,393.3c-0.3-0.4-0.6-0.7-0.9-1c-0.9-0.9-2-1.6-3.1-2.2c-3.2-1.4-7.1-1.2-10.5,1.9c-10.1,9.1,1.3,20.7,10.5,16.8
	c1.1-0.5,2.2-1.2,3.1-2.1c0.3-0.3,0.6-0.6,0.9-0.9c0,0,0.1-0.1,0.1-0.1C797.5,401.5,796.8,396.6,794.1,393.3z M790.3,402.8
	c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.3-0.8,0.6-1.2,0.8c-0.8,0.3-1.6,0.5-2.5,0.5
	c-0.9,0-1.7-0.2-2.5-0.5c-0.4-0.2-0.9-0.5-1.2-0.8c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.3-0.5-0.5-0.7-0.9c-0.6-0.9-1-2.1-1-3.3
	c0-0.7,0.1-1.4,0.3-2c0.2-0.6,0.6-1.2,1-1.7c0.3-0.4,0.8-0.8,1.2-1.1c0.9-0.6,2.1-1,3.3-1c1.9,0,3.6,0.9,4.6,2.3
	c0.8,1,1.2,2.2,1.2,3.6C791.3,400.7,790.9,401.9,790.3,402.8z"
        />
        <path
          class="st0"
          d="M743.3,393.1c-0.2,0.4-0.3,0.7-0.4,1.1c-0.1,0.4-0.1,0.7-0.1,1.1c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.1,0.1,0.2
	c0,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.7,0.7,1.1,0.9c0.3,0.2,0.7,0.3,1,0.3
	c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c1.2-0.2,2.4-1,3-2.2c1-1.9,0.4-4.2-1.4-5.1C746.4,390.3,744.2,391.2,743.3,393.1z"
        />
        <path
          class="st0"
          d="M729.2,392.5c-1.9,2-7.5,10.2-4.3,12.6c3.2,2.5,4.9,0.6,6.4-2.3c1.2-2.4,2.4-4.9,3.5-7.3
	C736.3,392.1,731.8,389.6,729.2,392.5z"
        />
        <path
          class="st0"
          d="M610,396.4l13.4,6.6c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
	c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l2.2-4.7c0.1-0.2,0-0.4-0.2-0.5l-13.5-6.4c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
	c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l-2.1,4.5
	C609.7,396.1,609.8,396.4,610,396.4z"
        />
        <path
          class="st0"
          d="M673.6,402.1l1.9-4.9c0.1-0.2,0-0.3-0.2-0.4c-8.3-1.4-16.5-3.2-24.6-5.5c-0.2-0.1-0.4,0-0.4,0.2l-1.8,5c0,0,0,0,0,0
	c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	c7.8,2.5,15.9,4.3,24,5.5C673.1,402.6,673.4,402.4,673.6,402.1z"
        />
        <path
          class="st0"
          d="M762.7,395.6c0,0.8,0.3,1.6,0.8,2.2c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.2,0.8,0.3,1.3,0.3
	s0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.4,1-0.7c0.6-0.6,1-1.4,1-2.3v0c0-1.1-0.6-2.1-1.4-2.7c-0.3-0.2-0.5-0.3-0.9-0.4s-0.6-0.1-1-0.1
	C764.2,392.3,762.7,393.8,762.7,395.6z"
        />
        <path
          class="st0"
          d="M625.6,404.2c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1l9,3.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2l2.3-5.4
	c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-9-3.8c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1
	c0,0-0.1,0.1-0.1,0.1l-2.3,5.4c0,0,0,0.1,0,0.1C625.6,404.1,625.6,404.1,625.6,404.2z"
        />
        <path
          class="st0"
          d="M636.9,409.1C636.9,409.1,637,409.2,636.9,409.1C637,409.2,637,409.2,636.9,409.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0l7.1,2.4c0.1,0,0.3,0,0.4-0.2l2.6-5.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-7.3-2.3c-0.2,0-0.3,0-0.4,0.2l-2.5,5.8c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0.1C636.9,409,636.9,409.1,636.9,409.1C636.9,409.1,636.9,409.1,636.9,409.1z"
        />
        <path
          class="st0"
          d="M738.9,405.2c-1.7,3.7-3.1,7.4-4.2,10.8c-1.5,4.6,5,6.8,7.1,1.9c0.5-1.1,3.9-10.3,2.8-12.5
	C743.6,403.6,740.1,402.6,738.9,405.2z"
        />
        <path
          class="st0"
          d="M536.4,423.4c1.5,0.7,2.6,0.4,3.4-0.7c0.1-0.2,0.2-0.4,0-0.6c-0.6-1.1-1.6-2-2.9-2.8c-7.9-4.8-15.7-8.3-23.4-12.6
	c-1.9-1.1-7.4-4.3-8.7-1.1c-0.1,0.2,0,0.4,0.2,0.5c7.9,3.5,15.4,7.6,22.4,12.2C530.2,420.3,533.2,422,536.4,423.4z"
        />
        <path
          class="st0"
          d="M483.7,405.9c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.2c1,0.6,1.9,1,2.5,1.2
	c5.7,1.9,11.4,3.9,17,6.1c6.3,2.5,12,6.7,17.4,10.6c1.5,1.1,3.1,1.2,4.8,0.2c7.2-4.3-36.6-23.6-41.8-19.6c-0.1,0.1-0.2,0.2-0.2,0.3
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0C483.7,405.7,483.7,405.8,483.7,405.9
	C483.7,405.9,483.7,405.9,483.7,405.9z"
        />
        <path
          class="st0"
          d="M646.1,412.1C646.1,412.1,646.1,412.1,646.1,412.1C646.1,412.1,646.1,412.1,646.1,412.1c0.1,0.1,0.1,0.1,0.1,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c6.6,2.3,13.4,3.9,20.3,4.7c1.7,0.2,2.8-2.5,2.8-3.7c0-0.7-0.3-1.1-0.9-1.3l-19.4-6c-0.2-0.1-0.4,0-0.5,0.2
	l-2.5,5.7c0,0,0,0,0,0c0,0,0,0,0,0C646.1,412,646.1,412,646.1,412.1C646.1,412,646.1,412,646.1,412.1
	C646.1,412,646.1,412.1,646.1,412.1z"
        />
        <path
          class="st0"
          d="M590.2,418.8c-2.1-0.9-4.4-1.7-6.3-3c-4.8-3.3-12.2-9.1-16.3-9.4c-3-0.2-0.5,2.6,0.3,3.2c8.3,5.9,16.1,12.2,25.1,17
	c1.6,0.8,4.3,2.8,5,4.7c0.5,1.4,1.1,3.4,3,3.4c2.4,0.1,3.5-2.3,2.8-4.4C602.5,425.7,594.4,420.6,590.2,418.8z"
        />
        <path
          class="st0"
          d="M766.8,420.1l11.4-2.2c0.4-0.1,0.4-0.2,0.2-0.5l-7.1-7.7c-0.2-0.2-0.3-0.2-0.4,0c-1.9,3.1-3.4,6.5-4.3,10.1
	C766.4,420.1,766.5,420.2,766.8,420.1z"
        />
        <path
          class="st0"
          d="M789.6,425.2l6.2-13.4c0.1-0.3,0.1-0.3-0.2-0.2l-11.6,5.6c-0.2,0.1-0.3,0.3-0.1,0.5l5.4,7.6
	C789.4,425.5,789.5,425.5,789.6,425.2z"
        />
        <path
          class="st0"
          d="M721.2,427.4c1.4-3.8,2.7-7.7,3.9-11.5c0.7-2.2-0.2-4.2-2.7-4.3c-1.8,0-2.3,1.4-2.9,2.8c-1.4,3.3-2.8,6.6-4.2,10
	c-0.9,2.1-1.3,4,1,5.3C718.7,430.9,720.3,429.7,721.2,427.4z"
        />
        <path
          class="st0"
          d="M752.2,418.8c-0.9,2-1.2,3.9-1.1,5.9c0,0.4,0.3,0.8,0.7,1c1.2,0.6,4.5,2.1,5.4,0.7c0.9-1.5,3.4-7,2.1-8.5
	C757.2,415.4,753.4,416.1,752.2,418.8z"
        />
        <path
          class="st0"
          d="M701.2,429.1c5.2,3.9,6.8-3.8,6.9-7c0-0.2-0.1-0.4-0.3-0.6c-1.3-1.2-2.9-1.7-4.7-1.5c-0.3,0-0.5,0.1-0.6,0.3
	c-1.5,1.9-2.6,4-3.1,6.4c-0.1,0.2,0,0.4,0.1,0.6C699.8,427.9,700.4,428.6,701.2,429.1z"
        />
        <path
          class="st0"
          d="M678.7,460.7c2.7,3.1,4.7,6.9,9.6,4.8c7.8-3.5,0.1-17-1.3-21.9c-0.6-2-1.3-8.3,2.2-8.4c4.9-0.1,7.1,14.1,8.1,17.1
	c1.1,3.3,4.3,7.1,8.1,6.3c6-1.2,3.8-11.7,6.3-16.2c0.9-1.6,2.2-2.9,3.8-3.8c0.7-0.4,1-1.3,0.6-2.1c-1.3-2.3-4-1.8-6-0.6
	c-5.3,3.3-5.4,9.5-4.8,15.4c0,0.5,0,0.9-0.1,1.4c0,0.2-0.2,0.4-0.3,0.5c-2.8,1.7-3.8-3.7-3.9-5.4c-0.7-6.5-3.5-19.2-13.1-17.4
	c-5.4,1-7.1,8-6.4,12.5c0.7,4.3,2.5,8.9,5.3,13.9c0.3,0.6,0.6,1.7,0.9,3.3c0,0.2,0,0.4-0.1,0.5c-0.3,0.7-0.8,1.1-1.6,1.2
	c-0.5,0.1-0.9-0.2-1.2-0.6c-1.1-1.7-2.3-3.8-4-4.8c-4.3-2.4-6.9,2.7-9.5,5.2c-2.5,2.5-5.7,2-7.3-1.1c-2.4-4.6,0.8-9.9,2.4-14.4
	c2.2-5.9,3.1-11.3,3.2-17.4c0-0.4,0.2-0.5,0.5-0.3c2.7,1,8.1,4.9,10.5,1.3c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c-3.3-1.8-6.8-3.3-10.4-4.3c-1.6-0.5-2.2,0.4-3.9-0.9c-8.3-6.8-15.6,5.4-17.7,11.7
	c-1.4,4.3-1.7,10-2.3,14.9c-0.3,2.3-1.2,4.3-2.7,6c-2.1,2.3-7.5-0.6-9.4,2.1c-1.1,1.7,0.6,3.3,2.1,3.7c7.5,2,15.6-0.3,15.7-9.6
	c0.1-8.5,0.2-15.7,5.1-23.1c2.2-3.3,6.7-3.8,7.4,0.9c0.3,1.7-0.4,4.9-1,6.5c-2.9,8.4-8.8,19.8-2.4,28.1c1.7,2.1,3.6,3.4,6,3.8
	c5.1,0.9,8.1-2.9,11.5-6.8C678.4,460.6,678.6,460.6,678.7,460.7z"
        />
        <path
          class="st0"
          d="M786.7,431.6c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.3-0.6-0.5-0.9
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.2-1.8-2.2-3-2.9c-0.5-0.3-1-0.5-1.6-0.8c-4.7-1.7-9.8,0.6-11.4,5.1c-1,2.8-0.5,5.9,1.2,8.2
	c0.3,0.5,0.7,0.9,1.2,1.3c0.9,0.8,1.9,1.4,3.1,1.8c1,0.4,2.1,0.5,3.1,0.6c0.3,0,0.7,0,1-0.1c0.2,0,0.5-0.1,0.7-0.1
	c0.2,0,0.3-0.1,0.5-0.1c1.3-0.3,2.4-0.9,3.5-1.7c0.1-0.1,0.3-0.2,0.4-0.4c1-0.9,1.7-2,2.2-3.3c0.2-0.6,0.4-1.3,0.5-1.9
	c0-0.2,0-0.4,0.1-0.7v0C786.8,432.6,786.7,432.1,786.7,431.6z M777.5,438.3c-3.1,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5
	s5.5,2.5,5.5,5.5C783,435.8,780.6,438.3,777.5,438.3z"
        />
        <path
          class="st0"
          d="M733.9,438C733.9,438.1,734,438,733.9,438c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
	c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0-0.1,0-0.1l1.1-4.7c0.1-0.4-0.1-0.8-0.5-0.9l-3.2-1.2
	c-0.4-0.1-0.8,0-1,0.4l-1.8,4.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0l3.9,1.6
	C733.8,438,733.9,438,733.9,438z"
        />
        <path
          class="st0"
          d="M765.4,439.5l-0.5-5.4c0-0.3-0.2-0.4-0.4-0.2c-0.9,0.8-4.5,3.6-4.6,4.5c0,0.3,0.2,0.6,0.5,0.7l4.5,0.7
	C765.3,439.9,765.5,439.8,765.4,439.5z"
        />
        <path
          class="st0"
          d="M575.4,484.3c0,0.5,0,1.1,0,1.6c-2.9-1.5-5.9-3.1-9-4.7c-0.1-0.6-0.1-1.2-0.2-1.8c-0.8-5.7-2.6-11.2-5.3-16.5
	c-5-9.6-12.9-19.1-23.2-22.3c-4.9-1.5-1.9-5.2,1.9-5.1c9.3,0,15.7,4.9,21.7,11.7C570.7,458,575.4,470.3,575.4,484.3z"
        />
        <path
          class="st0"
          d="M756.5,442.3c-0.5-1.5-1.3-2.8-2.4-3.8c-0.7-0.6-1.4-1.1-2.2-1.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.2-0.8-0.3-1.2-0.4
	c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4-0.1-0.7-0.1-1.1-0.2c-0.3,0-0.6,0-0.9,0c-0.9,0-1.9,0.2-2.8,0.5c-1.1,0.3-2.1,0.9-3,1.5
	c-0.9,0.7-1.6,1.4-2.2,2.3c-0.4,0.6-0.8,1.3-1,2c-0.3,0.7-0.4,1.4-0.5,2.2c-0.1,1.2,0,2.4,0.4,3.7c0.2,0.6,0.4,1.2,0.7,1.7
	c0.3,0.5,0.7,1.1,1.1,1.5c1.6,1.9,4,3,6.6,3.1c0.2,0,0.4,0,0.7,0c0.6,0,1.3-0.1,1.9-0.2c0.3-0.1,0.6-0.2,1-0.2
	C755.2,452.4,758,447.2,756.5,442.3z M752.9,445.8c-0.1,0.9-0.4,1.8-0.8,2.5c-0.2,0.4-0.5,0.7-0.8,1c-0.2,0.2-0.4,0.4-0.7,0.6
	c-0.6,0.4-1.3,0.7-2,0.9c-0.4,0.1-0.8,0.1-1.1,0.2c-0.2,0-0.4,0-0.6,0c-3.1-0.2-5.4-2.6-5.4-5.5c0-0.1,0-0.2,0-0.3
	c0.1-1.7,1-3.1,2.3-4c0.2-0.1,0.4-0.3,0.6-0.4c0.5-0.3,1-0.5,1.6-0.6c0.4-0.1,0.8-0.1,1.1-0.1c0.2,0,0.4,0,0.6,0
	C750.6,440.1,753.1,442.8,752.9,445.8z"
        />
        <path
          class="st0"
          d="M786.7,447.1c2.7-1.4,6.1-2.8,7.8-5.3c2.3-3.4-2.5-5.6-4.8-3.9c-2.2,1.5-2.9,6.5-3.2,9.1
	C786.4,447.2,786.5,447.3,786.7,447.1z"
        />
        <path
          class="st0"
          d="M784.8,464.2C784.9,464.2,784.9,464.3,784.8,464.2c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0.1,0.1
	c0,0,0,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.1c6.3,2.6,16.5-13.5,18.7-18
	c0.1-0.2,0.1-0.4-0.1-0.5c-1.3-0.8-4.3-2.4-4.9-3.8c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
	c-4.2,7.3-8.1,14.3-13.3,20.8c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	C784.8,464,784.8,464.1,784.8,464.2C784.8,464.1,784.8,464.2,784.8,464.2z"
        />
        <path
          class="st0"
          d="M676.5,445.8c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.2-0.4-0.3-0.7-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
	c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6,0-0.8,0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.1-0.6,0.3-0.8,0.5
	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.3-0.4,0.5-0.6,0.9c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.1,0.5-0.2,0.7c0,0,0,0,0,0
	c0,0.1,0,0.3,0,0.4c0,0.4,0,0.7,0.1,1.1c0.1,0.2,0.1,0.5,0.2,0.7c0.4,0.8,1.1,1.5,2,1.8c0.2,0.1,0.4,0.1,0.7,0.2c0,0,0,0,0,0
	c0.1,0,0.2,0,0.4,0c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.5-0.4,0.8-0.7
	c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.3-0.6,0.5-1c0.1-0.2,0.1-0.5,0.2-0.7c0-0.1,0-0.3,0-0.4c0-0.2,0-0.3,0-0.5
	c-0.1-0.5-0.2-1-0.4-1.4C676.6,445.9,676.5,445.8,676.5,445.8z"
        />
        <path
          class="st0"
          d="M725.5,444.6c-5.2-1.2-10.3,1.9-11.5,6.8c-1.2,5,2.1,10,7.3,11.2c0.6,0.2,1.3,0.2,1.9,0.3c0.5,0,1,0,1.5-0.1
	c0.2,0,0.5-0.1,0.7-0.1c1.2-0.2,2.3-0.7,3.4-1.3c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.9,2.1-2,2.7-3.3c0.1-0.2,0.2-0.5,0.3-0.7
	c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.7,0.2-1.4,0.2-2C733,449.6,730,445.7,725.5,444.6z M724.8,459.2c-0.2,0-0.3,0.1-0.5,0.1
	c-0.4,0.1-0.7,0.1-1.1,0.1c-3.1,0-5.5-2.5-5.5-5.6c0-1.7,0.8-3.3,2-4.3c0.3-0.2,0.6-0.4,0.9-0.6c0.8-0.4,1.7-0.7,2.6-0.7
	c0.4,0,0.8,0,1.1,0.1c0.2,0,0.3,0.1,0.5,0.1c2.3,0.7,4,2.8,4,5.3C728.8,456.4,727.1,458.5,724.8,459.2z"
        />
        <path
          class="st0"
          d="M771.4,445c-5.2,0-9.5,4.2-9.5,9.5c0,5.2,4.2,9.5,9.5,9.5c5.2,0,9.5-4.2,9.5-9.5C780.8,449.2,776.6,445,771.4,445z
	 M777.2,455.2c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.2,0.4-0.3,0.5c-1.3,2.3-3.9,3.5-6.5,2.8
	c-2.3-0.6-3.8-2.6-4.1-4.9c0-0.4-0.1-0.8,0-1.2c0-0.4,0.1-0.8,0.2-1.2c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.3-0.7,0.5-1
	c0.3-0.5,0.7-0.9,1.1-1.3c0.4-0.4,0.9-0.7,1.4-0.9c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,1.1-0.3,1.7-0.3c0.5,0,0.9,0.1,1.4,0.2
	c0.4,0.1,0.7,0.2,1.1,0.4c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.4,0.4,0.5,0.6c0,0,0.1,0.1,0.1,0.1
	c0.2,0.3,0.4,0.6,0.6,1c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.3,0.1,0.5,0.2,0.8c0,0,0,0.1,0,0.2C777.3,454.1,777.3,454.7,777.2,455.2z"
        />
        <path
          class="st0"
          d="M602.5,496.5c1.1-1.2,1.6-2.4,1.7-3.6c0.9-10.3,2.2-25.1,0.8-36.7c0-0.2-0.2-0.4-0.4-0.4l-5.4-0.2c-0.3,0-0.4,0.1-0.5,0.4
	c-1.2,10.8-2,22.2-2.6,34.4c-0.1,2,0,3.7,0.1,5.2c0,0.1,0.1,0.2,0.2,0.2l5.3,0.9C602.1,496.7,602.3,496.6,602.5,496.5z"
        />
        <path
          class="st0"
          d="M531.6,461.8c-1.5-0.8-3.1-1.5-4.7-2.3c-0.3-0.2-0.6-0.3-0.8-0.4c0.1-0.3,0.2-0.6,0.4-0.8c2.1-3.3,4.5,1.5,5,3.1
	C531.5,461.6,531.6,461.7,531.6,461.8z"
        />
        <path
          class="st0"
          d="M560.2,476.4c-1.5-0.8-3-1.5-4.6-2.3c-1.4-0.8-2.9-1.5-4.4-2.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.9-3.9-1.5-7.3-1.8-10.2
	c-0.4-3.6,2.8-5.7,5.2-2.5c0.8,1.1,1.8,3.1,2.8,6C558.5,468.9,559.6,472.7,560.2,476.4z"
        />
        <path
          class="st0"
          d="M754.2,466.1c0.3,0.1,0.7,0.2,1,0.2c1.9,0,3.4-1.5,3.4-3.4c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.5-0.1-0.7
	c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.2-0.5-0.4-0.8-0.6c-0.5-0.3-1-0.4-1.6-0.4
	c0,0,0,0,0,0c-0.3,0-0.5,0-0.8,0.1c-0.2,0.1-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.9,0.6-1.5,1.7-1.5,2.8
	C751.8,464.3,752.8,465.6,754.2,466.1z"
        />
        <path
          class="st0"
          d="M742.8,460.6c-0.1-0.2-0.3-0.3-0.5-0.4c0,0-0.1-0.1-0.1-0.1c-0.5-0.3-1.1-0.5-1.7-0.6c-0.2,0-0.4,0-0.6,0
	c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0.1-1,0.2c0,0-0.1,0-0.1,0c-0.6,0.2-1,0.6-1.4,1c0,0,0,0,0,0c-0.3,0.3-0.5,0.6-0.6,1
	c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.4,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.4,0.5,0.6,0.8
	c0.8,0.7,1.9,1,3,0.9c1-0.1,1.9-0.6,2.6-1.2c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.6,0.7-1.4,0.6-2.2c-0.1-0.5-0.2-1-0.5-1.4
	C743,460.8,742.9,460.7,742.8,460.6z"
        />
        <path
          class="st0"
          d="M623,465.9l-6.9-0.5c-0.1,0-0.2,0.1-0.2,0.2v0l-0.6,8.5c0,0.1,0.1,0.2,0.2,0.2l0.4,0l6.5,0.4c0,0,0.1,0,0.1,0
	c0,0,0.1-0.1,0.1-0.1l0.6-8.5C623.1,466,623.1,465.9,623,465.9C623,465.9,623,465.9,623,465.9z"
        />
        <path
          class="st0"
          d="M725.4,556.8c-0.8,1.7-1.6,3.5-2.6,5.4c-0.4,0.9-0.9,1.8-1.3,2.7c-3.4-5.2-6.1-10.9-8.7-17.1c-8.7-21.3-12.3-44.9-14.7-67.7
	c-0.4-4.1-1.3-8.7-1.7-12.1c-0.1-1,0.2-1.8,1-2.4c0.2-0.1,0.4-0.2,0.5-0.2l4.6,0.3c0.5,0,0.9,0.4,1.1,0.8c3.7,11.2,5.1,23.3,6.5,35
	C712.5,520.7,716.1,540.5,725.4,556.8z"
        />
        <path
          class="st0"
          d="M636.5,470l-7-0.9c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l-0.5,7.1
	c0,0.1,0.1,0.2,0.2,0.2l7.1,0.7c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l0.4-6.9
	C636.7,470.1,636.6,470,636.5,470z"
        />
        <path
          class="st0"
          d="M674.8,483.6c-4.5-7.2-13.5-14-22.5-10.5c-9.5,3.8-11.3,17.2-8.4,25.8c4.6,14.1,22.2,17.2,30.8,5.2
	C679.3,497.9,678.9,490.2,674.8,483.6z M670.9,499.2c-5.5,11.6-20.8,4.9-23-5.1c-1.3-6.3,0.1-11.5,4.2-15.6
	c5.7-5.7,14.5,1.6,17.6,6.4C672.8,489.7,673.2,494.5,670.9,499.2z"
        />
        <path
          class="st0"
          d="M688.2,507.8C688.2,507.8,688.2,507.8,688.2,507.8C688.2,507.9,688.2,507.9,688.2,507.8
	C688.2,507.9,688.2,507.9,688.2,507.8C688.2,507.9,688.2,507.9,688.2,507.8C688.2,507.9,688.2,507.9,688.2,507.8
	c0.1,0.1,0.1,0.1,0.1,0.1l6.3-2.2c0.3-0.1,0.4-0.4,0.4-0.6l-5.4-32.6c0-0.2-0.2-0.3-0.4-0.3l-6.7,0.6c-0.2,0-0.3,0.1-0.3,0.3
	C684.6,484.7,686.5,496.2,688.2,507.8z"
        />
        <path
          class="st0"
          d="M622.4,479.1l-7.6-0.6l0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1l-0.7,9.3c0,0.1,0.1,0.2,0.2,0.2
	l7.6,0.6h0c0,0,0.1,0,0.1,0c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1l0.7-9.3C622.6,479.2,622.5,479.1,622.4,479.1z"
        />
        <path
          class="st0"
          d="M635.8,480.8l-7.4-0.4c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0l-0.5,8.3c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1l7,0.4
	l0.4,0h0c0.1,0,0.2-0.1,0.2-0.2l0.4-7.9l0-0.5v0C636,480.9,635.9,480.8,635.8,480.8z"
        />
        <path
          class="st0"
          d="M666.2,487.7c-2.3-4.9-7.3-7.3-11.3-5.5c-4,1.9-5.3,7.3-3,12.2c2.3,4.9,7.3,7.3,11.3,5.5C667.1,498,668.5,492.5,666.2,487.7
	z M658.9,492c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C660.9,491.1,660,492,658.9,492z"
        />
        <path
          class="st0"
          d="M621.7,493.4l-8.1-1c-0.1,0-0.2,0.1-0.2,0.2l-0.8,6.9c0,0.1,0.1,0.2,0.2,0.2l8.5,1.3c0,0,0,0,0,0c0,0,0,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l0.4-7.3C621.8,493.5,621.8,493.4,621.7,493.4z"
        />
        <path
          class="st0"
          d="M635.3,493.2C635.3,493.2,635.2,493.2,635.3,493.2c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0l-7.6,0.3c-0.1,0-0.2,0.1-0.2,0.2
	l-0.8,10.7c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l7.9,0.3c0,0,0,0,0,0c0,0,0,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l0.6-11.3c0,0,0,0,0,0C635.3,493.3,635.3,493.3,635.3,493.2
	C635.3,493.3,635.3,493.2,635.3,493.2z"
        />
        <path
          class="st0"
          d="M621.1,505.9l-1.1,10.6c0,0.2-0.2,0.3-0.4,0.3l-0.5,0v-1.9c0-0.5-0.3-1.2-0.9-1.9c-0.1-0.1-0.2-0.3-0.4-0.5
	c-1.2-1.4-3.3-3.2-6.1-5.2l0-0.6l0.2-2.5c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1,0
	c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.1l8.3,1.6C621,505.5,621.1,505.7,621.1,505.9z"
        />
        <path
          class="st0"
          d="M690.9,522C690.9,522,691,522,690.9,522c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l6.6-2.2
	c0.1,0,0.2-0.1,0.1-0.2l-2.2-10c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0l-6.6,2.1
	c-0.1,0-0.2,0.1-0.1,0.2L690.9,522C690.9,522,690.9,522,690.9,522z"
        />
        <path
          class="st0"
          d="M654.6,517.9C654.6,517.9,654.6,517.8,654.6,517.9c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0l-7.2-1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1
	c0,0,0,0-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0l-0.4,4.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0l6.4,0.5
	c0.2,0,0.5-0.1,0.6-0.4l1.3-3.9c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1C654.6,518.1,654.6,518,654.6,517.9
	C654.6,518,654.6,517.9,654.6,517.9z"
        />
        <path
          class="st0"
          d="M727.2,518.5l-7.2,1.9c-0.1,0-0.2,0.1-0.2,0.3l2.3,10.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l7.4-2.4c0.1,0,0.2-0.1,0.1-0.3l-2.5-10.2
	C727.5,518.5,727.3,518.5,727.2,518.5z"
        />
        <path
          class="st0"
          d="M619.6,520.6C619.6,520.6,619.6,520.6,619.6,520.6C619.6,520.6,619.6,520.6,619.6,520.6l-0.5,3v-3.2l0.2,0h0c0,0,0,0,0,0
	s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0S619.6,520.5,619.6,520.6C619.6,520.5,619.6,520.5,619.6,520.6
	C619.6,520.6,619.6,520.6,619.6,520.6z"
        />
        <path
          class="st0"
          d="M718.4,571.1c-0.8,1.7-1.7,3.4-2.6,5.2c-0.3,0.5-0.6,1.1-0.8,1.7c-4.7-5.9-8.9-12.2-12.2-19.4c-4.8-10.5-8.4-21.3-10.8-32.7
	c0-0.2,0-0.3,0.2-0.4l6.3-2.1c0.2-0.1,0.4,0,0.4,0.2c4.3,16.4,10,31.9,18.2,45.4C717.6,569.8,718,570.5,718.4,571.1z"
        />
        <path
          class="st0"
          d="M731.2,532.9C731.2,532.9,731.2,532.9,731.2,532.9C731.2,532.8,731.2,532.8,731.2,532.9
	C731.2,532.8,731.2,532.8,731.2,532.9c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	l-7.6,2.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l4.4,11.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0l7.3-3.4c0.1,0,0.2-0.2,0.1-0.3L731.2,532.9C731.2,532.9,731.2,532.9,731.2,532.9z"
        />
        <path
          class="st0"
          d="M642.8,579.9c16-13.4,31.8-26.8,48.4-39.4c0.1-0.1,0.2-0.3,0.1-0.4l-1.8-6.3c-0.1-0.2-0.2-0.3-0.4-0.1
	c-17.5,13.1-34,27.4-50.7,41.5c-0.2,0.2-0.2,0.4,0,0.6l3.8,4.2C642.4,580.1,642.6,580.1,642.8,579.9z"
        />
        <path
          class="st0"
          d="M624.9,547.5l6.8,0c0.1,0,0.2-0.1,0.2-0.2l0,0l0-9.4c0-0.1-0.1-0.2-0.2-0.2l-6.8,0l0,0l0,0c0,0,0,0,0,0
	c-0.1,0-0.1,0.1-0.1,0.1v0l0,9.4C624.8,547.4,624.8,547.5,624.9,547.5z"
        />
        <path
          class="st0"
          d="M697.7,558.7l-1.5-5.3c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
	c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-15.7,12.8-31.1,25.9-46.2,39.4c-0.2,0.2-0.2,0.4,0,0.6l3.7,4.4
	c0.2,0.2,0.4,0.3,0.6,0.1c0.6-0.3,1.1-0.7,1.6-1.1c14-12.5,28.1-24.9,42.2-37.3C697.8,559.2,697.8,559,697.7,558.7z"
        />
        <path
          class="st0"
          d="M626.2,572.6c0,0.2,0.2,0.4,0.4,0.4l5.4-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l-0.4-9c0-0.2-0.2-0.4-0.4-0.4l-6.1,0.2c0,0,0,0,0,0
	c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0L626.2,572.6z"
        />
        <path
          class="st0"
          d="M704.1,577.4c-1,0.9-1.9,1.8-2.9,2.7c-9.7,8.6-19.3,17.3-28.8,26.2c-2.3-1-4.5-2-6.8-2.9c11.4-9.9,22.7-19.9,34-29.8
	c0.6-0.6,1.3-1.2,1.9-1.8c0.2-0.2,0.3-0.2,0.5,0.1l2.2,5C704.4,577,704.3,577.2,704.1,577.4z"
        />
        <path
          class="st0"
          d="M634.4,590L634.4,590C634.4,590.1,634.4,590.1,634.4,590c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0h0l-1.1,0l0,0h0c-2-1-3.9-1.9-5.5-2.8l-0.1-6.4c0-0.2,0.1-0.3,0.3-0.3l5.7-0.5c0,0,0,0,0,0
	c0,0,0,0,0.1,0h0.1c0,0,0,0,0.1,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0L634.4,590z"
        />
        <path
          class="st0"
          d="M714.6,588.2c-0.1,0.3-0.3,0.6-0.4,0.8c-0.2,0.3-0.3,0.7-0.5,1c-0.7,1.3-1.3,2.6-2,3.9c-1.5-1.2-3-2.7-4-4.1
	c-0.1-0.2-0.1-0.3,0-0.4l3.9-4.1c0.2-0.2,0.3-0.2,0.5,0L714.6,588.2z"
        />
        <path
          class="st0"
          d="M710.7,596.1l-0.1,0.1c-1.4,2.7-2.7,5.5-4.1,8.3c-0.3,0.5-0.6,1.1-0.9,1.7c0,0,0,0,0,0c0,0,0,0,0,0l-1,1.2c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-7.3-6.6v0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v0l8.7-8.8c0.1-0.1,0.2-0.1,0.3,0L710.7,596.1z"
        />
        <path
          class="st0"
          d="M695.2,602.9c-0.2-0.1-0.3-0.1-0.5,0l-4.8,4.8c-0.2,0.2-0.1,0.3,0,0.4l6.5,6l1.9,0.1l4.5-5c0.2-0.2,0.2-0.4,0-0.5
	L695.2,602.9z"
        />
        <path class="st0" d="M687.5,610.4l-4,4h9.3l-4.5-4C688.1,610.1,687.7,610.1,687.5,610.4z" />
        <path
          class="st0"
          d="M1276.4,0c0,5.4-1.1,9.7-5.9,14.5c-16,16-56.7,19.3-73,49.9c-16.2,30.7-7.1,44.2-25.2,62.2
	c-18.1,18-71.2,82-128.4,139.3c-57.2,57.2-92.3,50.8-121.1,79.6c-28.9,28.9-90.2,100-105.5,111.9h-0.1c-3.8-2.9-7.2-5.8-10.4-8.8
	c-3.6-4.7-7.7-10.9-12.4-18.6c-0.3-1.2-0.4-2.3-0.4-3.5v-7.8c0.1-6.9,0.1-17.1,0.1-29.2c0.1-6.1,0.1-12.8,0.2-19.7
	c0.1-13.1,0.2-27.5,0.3-42.2c0.1-9,0.1-18,0.2-27.1c0.1-6.4,0.1-12.6,0.2-18.9c0-3.2,0.1-6.5,0.1-9.7c0-4.9,0-9.6,0.1-14.4
	c0-4.2,0-8.2,0.1-12.2c0.2-27.6,0.4-48.9,0.4-54.8c0-0.9,0.1-1.8,0.3-2.7c0.2-1.1,0.7-2,1.1-3.1c0.9-2.2,2.3-4.5,4-7v-0.1
	c1.2-1.6,2.5-3.3,3.8-5.1c0.6-0.9,1.4-1.7,2.1-2.7c1.6-1.7,3.1-3.6,4.8-5.4c1.6-1.8,3.4-3.7,5.2-5.5c0.9-0.9,1.7-1.8,2.7-2.7
	c0.9-1,1.8-2,2.8-2.8c1.8-2,3.7-3.7,5.5-5.4c3.7-3.6,7.2-6.9,10.6-10.2c0.9-0.8,1.7-1.5,2.5-2.3c1.6-1.6,3.1-3,4.5-4.3
	c1.4-1.4,2.7-2.7,3.8-3.7c1.1-1.1,1.9-2.1,2.7-2.8c0,0,0,0,0.1-0.1l93.6-52.3l1.1-0.6l79.7-18.3c13.5-3,25.7-10,35.2-20.2
	c8.1-8.7,19.1-20.4,31.2-33.3H1276.4z"
        />
        <path
          class="st0"
          d="M428.2,128.7c0.1,0.3,0.2,0.6,0.4,0.9c0.2,0.4,0.3,0.7,0.5,1.1c2.7,6.4,5.5,13.4,8.5,20.6
	c1,2.6,2.1,5.2,3.2,7.9c0.9,2.3,1.9,4.6,2.8,6.9c0.8,2,1.6,4.1,2.4,6.1c1,2.5,2,5,3,7.5c0.8,2.1,1.7,4.2,2.5,6.3
	c1.7,4.4,3.3,8.6,4.8,12.6c1,2.6,1.9,5.1,2.8,7.5v0c1.3,3.5,2.5,6.9,3.5,9.9c0,0.1,0.1,0.2,0.1,0.3c1.1,3.4,1.8,6.6,2,9.7
	c1.4,18.4-11.8,32.6-25.3,46.8c0,0-0.1,0.1-0.1,0.1c-0.9,0.9-1.7,1.8-2.6,2.7c0,0,0,0,0,0c-8.5,9-16.6,18.1-20.9,28.5
	c-2,4.9-3.8,10.4-5.4,16.2v0c-5.4,19.8-8.3,43.6-7.4,60.6c0,0,0,0,0,0c0.3,6,1.2,11.1,2.5,14.9c-0.4-0.6-0.8-1.2-1.3-1.8
	 M424.6,119.9c-2.5-5.9-4.6-11.1-6.4-15.2c-0.5-1.2-1-2.3-1.4-3.3c-1.6-3.8-2.5-5.9-2.5-5.9c-0.2,0-0.4,0-0.6,0.1
	c-58.8,5.3-118,42.7-174.7,36.9c-21.5-2.3-40.3-10.5-59.3-18.3c20.6,25,35.6,53.5,35.6,78.6c0,31-78.4,141.8-78.4,154
	c0,7.1,7.9,34.4,10.4,60v0c0,0,0,0,0,0"
        />
        <path class="st0" d="M579.6,488.2c-1.4-0.7-2.8-1.5-4.2-2.2" />
        <path
          class="st0"
          d="M566.4,481.2c-2.2-1.1-4.5-2.3-6.7-3.5c-1.4-0.8-2.9-1.5-4.4-2.3c-5.9-3-12.1-6.2-18.4-9.3c-2-1-3.9-1.9-6-2.9
	c-3-1.6-6.2-3.1-9.3-4.6c-10.3-5.1-20.8-10.2-30.8-15c-31.1-15.1-59.5-28.9-73.5-36.6c-1.2-0.6-2.1-1.2-3.1-1.7
	c-2.7-1.6-4.6-2.8-5.5-3.6c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c-1-1-1.6-2.1-2.3-3.6c0-0.1-0.1-0.1-0.1-0.2
	c-0.2-0.5-0.4-1.1-0.6-1.6v-0.1c-0.3-0.6-0.7-1.2-1.2-1.7"
        />
        <path
          class="st0"
          d="M147.4,406.9L147.4,406.9C147.3,406.9,147.3,406.9,147.4,406.9c0,0.2,0,0.3,0,0.5c0,0.6,0.1,1.4,0.2,2.2
	c0.1,1.8,0.3,3.7,0.4,5.5c0.6,14.7-1,27.9-7.8,35.2c-15.8,16.9-31.9,25.5-33.4,73.8c-0.4,11.8,0,25.8,1.6,43
	c6.1,66.5,6.1,123,12.2,152.9h562.6c-11.4-7-21.3-13.8-19.9-22.5c0.1-0.6,0.3-1.3,0.5-2.1c4-11.5,20.1-43.7,36.1-75.4
	c-4.4-1.9-8.9-3.8-13.4-5.7c-0.7-0.3-1.4-0.6-2.1-0.9c-19.9-8.5-39.1-16.9-51.4-23.1l0,0h0c-2-1-3.9-1.9-5.5-2.8
	c-3.4-1.9-5.9-3.4-7.3-4.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c-0.6-0.6-1-1-1-1.4v-0.2v-10.8v-46.9v-3.2v-3.6v-1.9
	c0-0.5-0.3-1.2-0.9-1.9c-0.1-0.1-0.2-0.3-0.4-0.5c-0.9-1-2.1-2.2-3.8-3.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.6-0.5-1.2-0.9-1.9-1.4
	c-5.6-4.1-14.2-9.1-24.6-14.9"
        />
        <path
          class="st0"
          d="M629.2,138.3c-2.1-0.6-4.2-1.2-6.3-1.8c-2.3-0.6-4.6-1.3-6.8-2c-10.1-3-20.3-6.3-30.2-9.8c-2-0.7-4-1.4-6-2.2"
        />
        <path
          class="st0"
          d="M852.3,124.7L852.3,124.7l-71.7,0H774c-0.4,0-0.7,0-1,0c-6-0.4-11.9-4.2-18.6,0c-4.8,3.2-9.8,6.7-15.5,10.3
	c-1.2,0.8-2.3,1.4-3.5,2.1c-1.4,0.8-2.8,1.7-4.3,2.4c-2.2,1.2-4.6,2.4-7,3.4c-2.3,1-4.7,2-7.2,2.8c-0.5,0.2-0.9,0.3-1.4,0.5
	c-1.3,0.4-2.7,0.9-4.1,1.2c-2.2,0.6-4.4,1.1-6.7,1.4c-1.4,0.3-2.8,0.5-4.3,0.6c-1.2,0.1-2.3,0.2-3.6,0.3c-1.1,0.1-2.3,0.1-3.4,0.2
	c-3,0.1-6.1,0-9.3-0.3c-1.7-0.1-3.5-0.3-5.3-0.6c-0.7-0.1-1.4-0.2-2.1-0.3c-0.9-0.1-1.8-0.3-2.7-0.4c-1.8-0.3-3.6-0.6-5.5-1
	c-4-0.7-8.2-1.6-12.6-2.5c-1-0.2-1.9-0.4-2.9-0.6c-0.6-0.1-1.3-0.3-1.8-0.4c-3.1-0.7-6.3-1.5-9.6-2.3c-0.6-0.2-1.3-0.3-1.9-0.5"
        />
        <path
          class="st0"
          d="M569.5,118.7c-5.9-2.2-11.6-4.5-17.1-6.9c-0.2-0.1-0.5-0.2-0.8-0.3c-0.9-0.4-1.7-0.8-2.6-1.1
	c-1.9-0.8-3.8-1.6-5.6-2.5c-0.8-0.3-1.6-0.7-2.4-1c-2-0.9-4-1.8-5.9-2.8c-0.9-0.4-1.8-0.8-2.6-1.3c-0.9-0.4-1.8-0.9-2.7-1.3
	c-2.5-1.3-5-2.5-7.3-3.8c-3.1-1.7-6-3.4-8.6-5c-3.1-2-5.8-3.9-8-5.8c-1.1-0.9-2.1-1.8-3-2.7c-0.9-0.9-1.8-1.8-2.6-2.6l0,0
	c-2.7-2.6-5.2-4.9-7.6-7c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-1.3-1.1-2.7-2.2-3.9-3.3
	c-0.3-0.2-0.6-0.5-1-0.8c-14.4-11.5-25.6-17.3-44-35.7c-7.8-7.8-14-20.4-19.5-34.7h669c-12.1,12.9-23,24.6-31.2,33.3
	c-9.5,10.2-21.7,17.2-35.2,20.2l-79.7,18.3l-1.1,0.6"
        />
      </svg>
    </div>
  );
}

export default AnimeBackground;
