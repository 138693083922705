import React from "react";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import AwardPage from "components/AwardPage";
import AwardNominate from "components/AwardNominate";
import AwardWinners from "components/AwardWinners";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import {} from "./content";

import ScrollUpButton from "react-scroll-up-button";

function About() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <div
        style={{
          backgroundColor: "#002245",
          backgroundImage: "url(" + require(`assets/images/awardsbackground.jpg`) + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: size ? "100%" : "auto 100%",
          backgroundPosition: "center",
          padding: size ? "5rem 0" : "1rem 0",
        }}
        className="section"
        data-parallax={true}
      ></div>
      <div
        style={{
          background: "#000",
          backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <AwardPage />
        <AwardNominate />
        <AwardWinners />
        <Footer />
      </div>
    </div>
  );
}

export default About;
