import React from "react";
import Navbar from "components/Navbar";
import RegisterForm from "components/Register";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
function Register({ title }) {
  return (
    <>
      <Navbar />
      <div
        style={{
          background: "#000",
          backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            backgroundColor: "transparent",

            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "8rem 0 1rem 0",
          }}
          className="section"
          data-parallax={true}
        >
          {title ? (
            <>
              <h1
                className={"text-center text-white text-700 mt-0"}
                style={{ zIndex: 10 }}
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
              <h6 className={"text-center  text-700 text-white mt-0"}>
                <Link to="/" className={"text-center text-primary text-700 mt-0"}>
                  HOME
                </Link>{" "}
                / <span dangerouslySetInnerHTML={{ __html: title }} />
              </h6>
            </>
          ) : (
            ""
          )}
        </div>

        <RegisterForm title={"Request Brochure"} titleHide={true} />
        <Footer />
      </div>
    </>
  );
}

export default Register;
