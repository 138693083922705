import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";
import S2 from "./svgs/s2";
import S3 from "./svgs/s3";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Digital Accessibility",
      description: "Bridging the gap through digital solutions, making banking accessible to all.",
      image: <S2 />,
    },
    {
      title: "Commercial Banking Evolution",
      description: "Reimagining commercial banking to accommodate changing markets.",
      image: <S3 />,
    },
    {
      title: "Secure Financial Systems",
      description: "Meeting regulatory demands with robust and secure financial systems.",
      image: <S8 />,
    },
    {
      title: "Regulatory Support",
      description:
        "Government-backed initiatives, like digitising remittances and encouraging digital payments, reinforce innovation's role. ",
      image: <S1 />,
    },
  ];

  const content1 = [
    {
      title: "Insights",
      description:
        "The future is a product of today's needs and aspirations, especially in the realm of digital banking and financial services. As we shape the technology landscape of tomorrow, our leaders, armed with a deep understanding of public desires, will share their visionary insights during the conference. Expect engaging panels, fireside chats, discussions, and demos covering carefully curated topics.",
    },
    {
      title: "Networking",
      description:
        "To nurture ideas, they must be exposed, discussed, refined through debates, and embraced. Finnovex offers you the chance to connect with influencers, industry stalwarts, thought pioneers, and innovators. Engage with these figures to absorb the best practices that are ushering in this transformative shift.",
    },
    {
      title: "Spotlight",
      description:
        "Reaching decision-makers can be challenging, but Finnovex simplifies this by gathering digital banking leaders in one accessible place. It's your platform to showcase your organization beyond conference attendees through print, online, email, and social media channels. prepare for personalized networking, key sessions, premium sponsor demos, insightful talks, presentations, and fireside chats with innovation leaders.",
    },
    {
      title: "Roundtable",
      description:
        "Elevate your networking experience with our premier roundtable break-out sessions. These intimate gatherings, each with around 10 participants, foster idea exchange. Guided by a roundtable leader, these sessions delve into compelling topics from various angles. It's an excellent blend of networking and idea sharing, enhancing your conference journey.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            // backgroundImage: "url(" + require("assets/images/asset.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={11}>
                {" "}
                <span className="text-white"></span>
                <h2 className="text-400 text-white">
                  Seizing the Fintech Boom:
                  <b>Innovations Reshaping the Financial Landscape </b>{" "}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  We stand at the threshold of a transformative era in the financial landscape of
                  East Africa! The 23rd Edition of Finnovex East Africa is not just an event; it is
                  a gathering of visionaries, innovators, and leaders who are committed to transform
                  the future of finance in East Africa.. In this digital age, the financial sector
                  is witnessing a profound revolution, redefining the way financial services are
                  accessed, delivered, and experienced. This edition of Finnovex East Africa serves
                  as a platform to delve into dynamic ways in which innovation can be positioned to
                  harness the digital wave and channel it towards greater financial inclusivity in
                  the region like never before.
                  <br />
                  <br />
                  <h3 className="text-700">Shaping the Future Together</h3>
                  <br />
                  East Africa's financial sector is undergoing a revolutionary shift driven by
                  technological innovation. Digital advancements and enhanced accessibility are
                  redefining banking and finance, fueling competition and customer-focused
                  solutions. This note outlines key innovation areas, Egypt's fintech progress, and
                  strategic imperatives shaping the region's financial future
                  <br />
                  <br />
                </h5>
              </Col>
              <Col lg={6} className="mt-5">
                <div className="container-fluid row">
                  {content.map((s, index) => (
                    <Col className="text-center  p-2" lg={6}>
                      <ScrollAnimation
                        animateIn={"fadeInUp"}
                        animateOnce={true}
                        duration={1.5 + index / 8}
                      >
                        <div className="pt-4 px-2 stat-div" style={{ minHeight: 270 }}>
                          {s.image}

                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                            }}
                            className="text-700 mt-4"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </div>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Unveiling Gold in Innovation: Shaping the Future of Banking and Finance
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 mb-5">
                  <Col lg={6} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-0 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6}>
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "DSC07491.jpg",
  "IMG_1530 (1).jpg",
  // "IMG_1531.jpg",
  "IMG_1539.jpg",
  "IMG_1545.jpg",
  "IMG_1548.jpg",
  "IMG_1555.jpg",
  "IMG_1558.jpg",
  "IMG_1565.jpg",
  "DSC07421 (1).jpg",
  "DSC07439.jpg",
];
