import React, { useEffect } from "react";
import anime from "animejs";

function S1({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s1",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        // width: 100,
      }}
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#645faa",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <rect class="s1" x="29.7" y="32.64" width="1.48" height="1.49" />
        <rect class="s1" x="26.73" y="32.64" width="1.48" height="1.49" />
        <path
          class="s1"
          d="M44.54,8.36A11.71,11.71,0,0,1,37.86,18.9V10.37H36.37V18.9A11.71,11.71,0,0,1,29.69,8.36V4.68A.26.26,0,0,1,30,4.43a9,9,0,0,0,6.42-2.66V5.91h1.49V1.77a9,9,0,0,0,6.42,2.66.25.25,0,0,1,.26.25V8.36Zm0,25a2.23,2.23,0,0,1-2.23,2.23H23.68a9.74,9.74,0,0,0,3.78-4.46H41.57a1.48,1.48,0,0,0,1.48-1.48v-13a13.45,13.45,0,0,0,1.49-2.27ZM29.69,41.54H25.24V37.09h4.45Zm7.8-1.48,1.11,1.48H31.18V40.06Zm-21.9,3H39.34v1.48H15.59Zm1.85-3h6.31v1.48H16.33Zm-7-12.62a8.17,8.17,0,1,1,8.17,8.17,8.18,8.18,0,0,1-8.17-8.17M4.68,43.93a1.86,1.86,0,0,1-1.35.58,1.84,1.84,0,0,1-1.25-3.19l8.66-8.25a9.48,9.48,0,0,0,2.19,2.19Zm5.71-31.34a2.23,2.23,0,0,1,2.23-2.22H28.37a12.17,12.17,0,0,0,.32,1.48H13.36a1.49,1.49,0,0,0-1.48,1.49v7.15a9.7,9.7,0,0,0-1.49,1.83ZM41.57,29.67H37.12V25.21h4.45Zm-4.71-9,.26.1.25-.1a13,13,0,0,0,4.2-2.5v5.52H35.63v5.94H27.94A9.64,9.64,0,0,0,13.36,19.32v-6H29.2a13.13,13.13,0,0,0,7.66,7.37M44.28,2.94A7.55,7.55,0,0,1,38.91.72a2.6,2.6,0,0,0-3.59,0A7.55,7.55,0,0,1,30,2.94a1.74,1.74,0,0,0-1.74,1.74V8.36c0,.18,0,.35,0,.52H12.62a3.72,3.72,0,0,0-3.71,3.71V27.44a9.54,9.54,0,0,0,1,4.33L1.06,40.23A3.33,3.33,0,0,0,3.33,46a3.33,3.33,0,0,0,2.43-1.06l8.47-8.89a9.54,9.54,0,0,0,4.33,1h5.19v1.49h-7L14.1,42v4H40.83V42l-2.6-3.46h-7V37.09H42.31A3.71,3.71,0,0,0,46,33.38V4.68a1.74,1.74,0,0,0-1.74-1.74"
        />
        <rect class="s1" x="36.37" y="7.4" width="1.48" height="1.49" />
        <rect class="s1" x="14.85" y="14.82" width="1.48" height="1.49" />
        <path
          class="s1"
          d="M28.21,17.05A2.23,2.23,0,0,0,26,14.82H17.82v1.49H26a.74.74,0,0,1,.74.74v2.23h1.49Z"
        />
        <path
          class="s1"
          d="M20,23.73H17.82v1.48H20a.75.75,0,0,1,.74.75v2.22h1.49V26A2.23,2.23,0,0,0,20,23.73"
        />
        <rect class="s1" x="38.6" y="26.7" width="1.48" height="1.49" />
        <path
          class="s1"
          d="M32.66,26a.74.74,0,0,1-.74.74H29.69v1.48h2.23A2.23,2.23,0,0,0,34.15,26V23.73H32.66Z"
        />
        <path
          class="s1"
          d="M31.18,25.21V23.73h-.74a.75.75,0,0,1-.75-.74V20.76H28.21V23a2.23,2.23,0,0,0,2.23,2.22Z"
        />
        <rect class="s1" x="31.18" y="20.76" width="2.97" height="1.49" />
        <rect class="s1" x="16.33" y="29.67" width="4.45" height="1.49" />
      </svg>
    </div>
  );
}

export default S1;
