import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "transparent",
        }}
        className="section pb-2"
      >
        <Container fluid>
          <Container>
            <Row className="mb-5">
              <Col lg={6}>
                <h4 className=" text-400 text-white text-justify">
                  <b>Exibex</b> stands unwavering in its mission to elevate and honor the remarkable
                  achievements of visionaries, pioneers, and daring trailblazers who redefine the
                  limits of their domains. Our salute extends to those who shatter conventions,
                  define trends, and ignite transformation.
                  <br />
                  <br />
                  The Finnovex Awards stand as a resolute testament to our relentless pursuit of
                  acknowledging and lauding the unwavering efforts of individuals, institutions, and
                  service providers within the dynamic realm of financial services. We exist to pay
                  homage to the architects of revolutionary shifts in the financial landscape.
                  <br />
                </h4>
                <br />
              </Col>
              <Col lg={6}>
                <h4 className=" text-400 text-white text-justify">
                  The Finnovex Award is more than an accolade—it's an emblem embodying unparalleled
                  innovation that paves a path toward unwavering excellence. With unwavering pride,
                  we honor and celebrate exceptional contributions within the banking and financial
                  services arena. Our purpose is singular: to recognize organizations and
                  individuals who exemplify consistent, outstanding performance. These prestigious
                  awards forge an uncompromising standard of excellence, simultaneously serving as
                  an unequivocal platform for financial institutions to showcase their prowess and
                  pinpoint the vanguards of the industry.
                  <br />
                  <br />
                  However, the Finnovex Awards are not confined to mere recognition; they wield the
                  power to ignite a fervor for achievement within other enterprises, catalyzing them
                  to surge beyond their objectives, driven by a benchmark that celebrates the zenith
                  of accomplishment.
                </h4>
                <br />
              </Col>
              {/* <Col lg={6} className={`mb-3 align-self-center`}>
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w500&id=1SdiW9k6RfDU9xT7NHMeDIg4IUBx0_HiI`}
                  width="100%"
                  alt="awards"
                  style={{
                    borderRight: "10px solid #47c2d8",
                    borderTop: "10px solid #47c2d8",
                  }}
                />
                
              </Col> */}

              <Col lg={12}>
                <h2 className="text-700 p-0 m-0 mb-5 text-white">CATEGORIES</h2>
              </Col>
              {content.map((data, index) => (
                <Col lg={3} key={index} className="stat-div border">
                  <div className="d-flex align-items-center" style={{ minHeight: "125px" }}>
                    <h5 className="text-700 mt-0 text-white">{data}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Excellence in Mobile Banking",
  "Excellence in Digital Banking",
  "Excellence in Fintech",
  "Excellence in Payments",
  "Excellence in Cyber Security",
  "Excellence in Customer Experience Enhancement",
  "Excellence in InsurTech",
  "Excellence in Open Banking",
  "Excellence in Cloud Banking",
  "Outstanding Contribution to Financial Inclusion Initiative",
  "Outstanding Contribution to Strategic National Development Initiatives",
  "Outstanding Contribution to Fintech Initiatives",
  "Finnovator of the Year, East Africa",
  "Finfluencer of the Year, East Africa",
  "Financial Women Trailblazer of the Year",
  "CEO of the Year",
  "COO of the Year",
  "CDO of the Year",
  "CIO of the Year",
  "CTO of the Year",
  "CFO of the Year",
  "CISO of the Year",
];
