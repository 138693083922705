import React, { useContext } from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
// import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import { size } from "./common";
import { DataContext } from "DataContainer";
import Placeholder from "../assets/images/speakers/speaker.png";
import { speakers as oldSpeakers } from "views/content";

function SpeakerCard({ speaker, old }) {
  const size = "23rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "22rem",
        background: "transparent",
        boxShadow: "none",
      }}
      className="rounded-0"
    >
      <Container fluid className="p-0">
        <img
          alt="..."
          className="img rounded-0 img-responsive"
          width="100%"
          src={
            old ? require(`assets/images/speakers/${speaker.image}`) : speaker.image || Placeholder
          }
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className="mt-2 px-1">
            <h4
              className="d-inline text-white text-700"
              style={{ fontSize: "22px", textTransform: "none" }}
            >
              {speaker.name}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white text-left px-1">
            <h5
              className="mt-1 text-400 text-white"
              style={{ textTransform: "none", fontSize: "14px", lineHeight: "14px" }}
            >
              {speaker.title}
            </h5>
            <h5
              className="text-700"
              style={{ fontSize: "14px", textTransform: "none", lineHeight: "14px" }}
            >
              {speaker.company && <span>{speaker.company}</span>}
            </h5>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function Speaker() {
  const { pastSpeakers: speakers } = useContext(DataContext);

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          overflow: "hidden",
        }}
        className="section"
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-700 text-center text-white mt-0 mb-5">2023 SPEAKERS</h2>
            </Col>
            {speakers.map((s, i) => (
              <Col
                lg={3}
                md={6}
                xs={6}
                key={i}
                className="p-1"
                style={{ background: " rgba(255,255,255,0.1)" }}
              >
                <SpeakerCard speaker={s} />
              </Col>
            ))}
          </Row>
          <Row className="mt-5">
            <Col lg={12}>
              <h2 className="text-700 text-center text-white mt-0 mb-5">2022 SPEAKERS</h2>
            </Col>
            {oldSpeakers.map((s, i) => (
              <Col
                lg={3}
                md={6}
                xs={6}
                key={i}
                className="p-1"
                style={{ background: " rgba(255,255,255,0.1)" }}
              >
                <SpeakerCard speaker={s} old />
              </Col>
            ))}
          </Row>

          <Row>
            <Col lg={12} className="text-center">
              <Button
                href="/speakers"
                className="btn my-2 text-center px-5"
                color="primary"
                size="lg"
              >
                View All
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
