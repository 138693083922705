import React, { useEffect } from "react";
import anime from "animejs";

export default function S11({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s11",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#645faa",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s11"
          d="M0,15.34l.21-.81a5.83,5.83,0,0,1,5.18-4.26l.46,0a5.19,5.19,0,0,1,7.56-3.77A8.81,8.81,0,0,1,29.1,3.75,15.66,15.66,0,0,1,31.43,3,5,5,0,0,1,37.1,6.9c.07.32.16.46.52.52a7.3,7.3,0,0,1,.25,14.39,6.85,6.85,0,0,1-1.28.12H28.8l-.29,0V20.48h8A5.84,5.84,0,0,0,37.1,8.82c-1.19-.11-1.18-.11-1.36-1.27a3.66,3.66,0,0,0-6-2.23c-.72.61-1.05.56-1.53-.27A7.3,7.3,0,0,0,14.71,7.51c0,.1,0,.2-.05.3a.73.73,0,0,1-1.27.46,3.89,3.89,0,0,0-2-.92,3.67,3.67,0,0,0-4.08,3.41c0,.8-.29,1-1.09,1a4.38,4.38,0,1,0-.35,8.75H9.45v1.39s0,.05-.07.05c-1.45,0-2.92.08-4.36-.08A5.68,5.68,0,0,1,.08,17,1.69,1.69,0,0,0,0,16.8Z"
        />
        <path
          class="s11"
          d="M31.4,36.56a2.92,2.92,0,1,0,0,5.84,2.92,2.92,0,0,0,0-5.84m-.57,7.3a5.63,5.63,0,0,1-2.09-.9,4.4,4.4,0,1,1,3.45.82l-.24.08Z"
        />
        <polygon
          class="s11"
          points="14.56 43.86 14.56 42.41 13.14 42.41 13.14 40.95 14.57 40.95 14.57 39.52 16.04 39.52 16.04 40.94 17.48 40.94 17.48 42.39 16.02 42.39 16.02 43.86 14.56 43.86"
        />
        <path
          class="s11"
          d="M19,14.64a6.57,6.57,0,1,0,6.55,6.6A6.55,6.55,0,0,0,19,14.64m0-1.47a8,8,0,1,1-8,8,8,8,0,0,1,8-8"
        />
        <path
          class="s11"
          d="M5.87,33.64a2.19,2.19,0,0,0-2.25,2.15A2.22,2.22,0,0,0,5.76,38,2.19,2.19,0,0,0,8,35.86a2.17,2.17,0,0,0-2.13-2.22m0-1.47a3.66,3.66,0,1,1-3.71,3.58,3.66,3.66,0,0,1,3.71-3.58"
        />
        <path
          class="s11"
          d="M39.43,29.25A1.46,1.46,0,0,0,38,27.78a1.49,1.49,0,0,0-1.46,1.48A1.47,1.47,0,0,0,38,30.7a1.45,1.45,0,0,0,1.47-1.45M38,32.16a2.92,2.92,0,1,1,2.93-2.9A2.92,2.92,0,0,1,38,32.16"
        />
        <polygon
          class="s11"
          points="24.83 37.27 24.83 38.33 24.83 38.72 23.38 38.72 23.38 40.16 21.91 40.16 21.91 38.75 20.47 38.75 20.47 37.28 21.89 37.28 21.89 35.85 23.35 35.85 23.35 37.27 24.83 37.27"
        />
        <polygon
          class="s11"
          points="30.66 24.86 32.13 24.86 32.13 26.3 32.13 27.74 30.66 27.74 30.66 26.3 30.66 24.86"
        />
        <rect class="s11" x="30.7" y="32.19" width="1.4" height="1.4" />
        <rect class="s11" x="33.62" y="17.58" width="1.4" height="1.42" />
        <rect class="s11" x="5.11" y="23.41" width="1.4" height="1.42" />
        <rect class="s11" x="37.26" y="23.43" width="1.42" height="1.39" />
        <rect class="s11" x="30.7" y="29.27" width="1.4" height="1.41" />
        <rect class="s11" x="21.93" y="30.01" width="1.41" height="1.4" />
        <rect class="s11" x="21.93" y="32.92" width="1.41" height="1.4" />
        <rect class="s11" x="14.61" y="36.58" width="1.4" height="1.41" />
        <rect class="s11" x="29.97" y="7.34" width="1.4" height="1.41" />
        <rect class="s11" x="32.9" y="7.34" width="1.4" height="1.41" />
        <rect class="s11" x="5.11" y="26.33" width="1.39" height="1.42" />
        <rect class="s11" x="5.11" y="29.27" width="1.41" height="1.39" />
        <rect class="s11" x="14.61" y="30.73" width="1.41" height="1.4" />
        <rect class="s11" x="14.62" y="33.66" width="1.39" height="1.41" />
        <path
          class="s11"
          d="M20.46,19.7c0-.23,0-.43,0-.65H17.54v1.43h2.71a1.48,1.48,0,0,1,1.65,1.63c0,.36,0,.72,0,1.07a1.48,1.48,0,0,1-1.66,1.67h-.52v1.43H18.26V24.86c-.77,0-1.54.06-2-.7a2.32,2.32,0,0,1-.2-1.47h1.41c0,.23,0,.44.05.67h2.88V21.94H17.7A1.51,1.51,0,0,1,16,20.3V19.15a1.47,1.47,0,0,1,1.61-1.59h.58V16.13h1.46v1.42c.74,0,1.55-.09,1.95.67A7.27,7.27,0,0,1,22,19.7Z"
        />
      </svg>
    </div>
  );
}
