import React, { useEffect } from "react";
import anime from "animejs";

export default function S6({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s6",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#645faa",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s6"
          d="M41.72,34.06a2.16,2.16,0,0,0,0,4.32,2.13,2.13,0,0,0,2.17-2.13,2.16,2.16,0,0,0-2.12-2.19M6.62,36.23a2.15,2.15,0,1,0-4.3,0,2.13,2.13,0,0,0,2.16,2.14,2.11,2.11,0,0,0,2.14-2.15m23.45,6.42a2.16,2.16,0,1,0,2.16,2.15,2.17,2.17,0,0,0-2.16-2.15m-13.92,0a2.16,2.16,0,1,0,2.13,2.18,2.17,2.17,0,0,0-2.13-2.18m-.74-1.34c0-.73,0-1.43,0-2.12a1.6,1.6,0,0,1,.37-.92c.89-1,1.83-1.84,2.73-2.77a.86.86,0,0,0,.26-.55c0-2.34,0-4.69,0-7,0-.08,0-.16,0-.27h-5c0,.2,0,.39,0,.58v7.64c0,.88-.17,1.05-1.05,1.05H8.46a.53.53,0,0,0-.64.43,3.51,3.51,0,0,1-3.9,2.39,3.56,3.56,0,0,1,0-7,3.52,3.52,0,0,1,3.87,2.37.57.57,0,0,0,.66.48c1.27,0,2.55,0,3.86,0V27.64l-.53,0c-1.43,0-2.86,0-4.29,0A7.39,7.39,0,0,1,6.25,12.92a1,1,0,0,0,1-.79,8.93,8.93,0,0,1,8.09-6.42,1.13,1.13,0,0,0,1-.53,12.06,12.06,0,0,1,3.57-3.29c.44-.27.82-.23,1,.11a.7.7,0,0,1-.31,1,11.17,11.17,0,0,0-3.66,3.61.89.89,0,0,1-.85.41,7.69,7.69,0,0,0-7.77,6.32c-.11.64-.3.8-1,.81a6,6,0,0,0,.11,12c4,0,8,0,12,0,2.05,0,4.09,0,6.14,0q6.48,0,13,0a6,6,0,1,0,.31-12c-.17,0-.34,0-.5,0-.57,0-.85-.28-.81-.84a11.32,11.32,0,0,0-.48-4.13.7.7,0,0,1,.44-1,.67.67,0,0,1,.87.59c.23,1.21.39,2.42.58,3.63,0,.1,0,.2,0,.25.76.18,1.5.29,2.2.53A7.39,7.39,0,0,1,39,27.6c-1.55,0-3.1,0-4.64,0l-.55,0c0,.13,0,.21,0,.29v7.59l.49,0c1.15,0,2.3,0,3.45,0a.51.51,0,0,0,.59-.42,3.53,3.53,0,0,1,3.83-2.44,3.55,3.55,0,0,1,.2,7,3.5,3.5,0,0,1-4-2.35.53.53,0,0,0-.63-.44H33.41c-.81,0-1-.19-1-1,0-2.59,0-5.19,0-7.78,0-.41-.11-.54-.53-.53-1.35,0-2.69,0-4,0l-.49,0v.51c0,2.22,0,4.43,0,6.64a1.16,1.16,0,0,0,.3.73c.87.91,1.77,1.8,2.67,2.68a1.28,1.28,0,0,1,.42,1c0,.68,0,1.36,0,2.08a3.62,3.62,0,0,1,2.48,1.92,3.45,3.45,0,0,1,.28,2.38,3.55,3.55,0,0,1-7-.33c-.27-1.8.76-3.27,2.82-4,0-.63,0-1.28,0-1.92a.8.8,0,0,0-.27-.44c-.9-.92-1.8-1.83-2.72-2.72a1.26,1.26,0,0,1-.4-1c0-2.33,0-4.66,0-7v-.59H20.19v.54c0,2.35,0,4.69,0,7a1.28,1.28,0,0,1-.4,1c-.92.89-1.83,1.8-2.71,2.72a1.15,1.15,0,0,0-.28.68c0,.56,0,1.13,0,1.68,2.15.77,3.13,2.25,2.79,4.19a3.55,3.55,0,0,1-7-.06,3.59,3.59,0,0,1,2.8-4.11"
        />
        <path
          class="s6"
          d="M35.1,3.79c-.14.19-.24.45-.43.54a.83.83,0,0,1-.69,0c-.64-.42-1.24-.88-1.84-1.36A.61.61,0,0,1,32,2.07a.61.61,0,0,1,.87-.22c.67.45,1.32.95,2,1.45.11.09.15.28.26.49"
        />
        <path
          class="s6"
          d="M26.72,0c.46.05.92.08,1.38.14a.69.69,0,0,1,.65.74.7.7,0,0,1-.8.64L26,1.42a.68.68,0,0,1-.73-.67A.7.7,0,0,1,26,0c.23,0,.46,0,.69,0V0"
        />
      </svg>
    </div>
  );
}
