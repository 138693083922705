import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Technological Advancements",
      description:
        "Innovation in finance involves the use of emerging technologies such as blockchain, artificial intelligence, and fintech solutions to create more efficient, secure, and cost-effective financial services.",
      image: <S2 />,
    },
    {
      title: "Digital Payments Evolution",
      description:
        "The rise of digital payment systems, mobile wallets, and peer-to-peer platforms has revolutionized the way people transact and manage their finances.",
      image: <S3 />,
    },
    {
      title: "Secure Financial Systems",
      description: "Meeting regulatory demands with robust and secure financial systems.",
      image: <S8 />,
    },
    {
      title: "Regulatory Framework",
      description:
        "Governments and regulators often play a crucial role in promoting inclusive finance by creating an enabling regulatory environment that encourages financial institutions to reach underserved populations.",
      image: <S1 />,
    },
  ];
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2.5rem" : "1.5rem" }}
              >
                <b>
                  Innovating for Inclusive Finance:
                  <br />
                  Transforming East Africa's Financial Landscape
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                We stand at the threshold of a transformative era in the financial landscape of East
                Africa! The 23rd Edition of Finnovex East Africa is not just an event; it is a
                gathering of visionaries, innovators, and leaders who are committed to transform the
                future of finance in East Africa.. In this digital age, the financial sector is
                witnessing a profound revolution, redefining the way financial services are
                accessed, delivered, and experienced. This edition of Finnovex East Africa serves as
                a platform to delve into dynamic ways in which innovation can be positioned to
                harness the digital wave and channel it towards greater financial inclusivity in the
                region like never before.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#645faa" }}>
                  Transforming the Financial Inclusion Together{" "}
                </b>
                <br />
                <br />
                East Africa's financial sector is undergoing a revolutionary shift driven by
                technological innovation. Digital advancements and enhanced accessibility are
                redefining banking and finance, fueling competition and customer-focused solutions.
                This note outlines key innovation areas, Egypt's fintech progress, and strategic
                imperatives shaping the region's financial future
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              <div className="container-fluid row pr-0">
                {content.map((s, index) => (
                  <Col className="text-center  p-2 px-0" lg={6} xs={6}>
                    <ScrollAnimation
                      animateIn={"fadeInUp"}
                      animateOnce={true}
                      duration={1.5 + index / 8}
                    >
                      <div className="pt-4 px-2 stat-div" style={{ minHeight: 350 }}>
                        {s.image}

                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-4"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </div>
                    </ScrollAnimation>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
